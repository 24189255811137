import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { HasStatutService } from '../shared/models/statut.model';
import { Devis } from './models/devis.model';
import { Panne } from '../pannes/models/panne.model';

@Injectable()
export class DevisService implements HasStatutService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/reparation/devis';
    }

    modifier(entity: Devis, produitId: string) {
        console.log('start devis');
        return this.http.post(this.url + '/' + produitId, entity)
            .toPromise()
            .then(response => { console.log('end devis'); })
            ;
    }


    changerStatut(entity: Devis, identifiant: string, commentaire: string): Promise<Devis> {
        return this.http.put(this.url + '/changer-statut/' + entity._id, { identifiant: identifiant, commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);

                return entity;
            })
            ;
    }

    retirerStatut(entity: Devis, commentaire: string): Promise<Devis> {
        return this.http.put(this.url + '/retirer-statut/' + entity._id, { commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);


                return entity;
            })
            ;
    }

    calculatePrice(devis: Devis): Promise<Devis> {
        return this.http.post(this.url + '/calculate-price', devis)
            .toPromise()
            .then(response => { return new Devis().deserialize(response.json()) as Devis })
            ;
    }
    

}
