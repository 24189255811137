export class StatutDto {
    nom: string;
    type: string;
    identifiant: string;
    createdAt: Date;
    createdBy: string;
}

export class HistoriqueStatutsDto {
    statuts: StatutDto[];
}