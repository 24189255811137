import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-wrapper-validation-messages',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div>
            <small class="text-muted text-danger" *ngIf="showError" role="alert" [id]="validationId">
                <app-formly-validation-message [fieldForm]="formControl" [field]="field"></app-formly-validation-message>
            </small>
        </div>
    `,
})
export class FormlyWrapperValidationMessages extends FieldWrapper {
    @ViewChild('fieldComponent', {read: ViewContainerRef}) fieldComponent: ViewContainerRef;

    get validationId() {
        return this.field.id + '-message';
    }
}
