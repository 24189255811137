import { Component, Input } from '@angular/core';
import { StatutDto } from '../dtos/statut.dtos';

@Component({
    template: `
        <modal size="large">
            <header>
                Historique des statuts
            </header>

            <table class="table table-bordered table-striped table-condensed">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Statut</th>
                        <th>Commentaires</th>
                        <th>Utilisateur</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let statut of statuts">
                        <td>{{ statut.createdAt | amCalendar: { sameElse: 'DD/MM/YYYY HH:mm' }  }}</td>
                        <td><span class="label " [ngClass]="'label-' + statut.type">{{ statut.nom }}</span></td>
                        <td>{{ statut.commentaire || '-' }}</td>
                        <td>{{ statut.createdBy || '-' }}</td>
                    </tr>
                </tbody>
            </table>

            <footer>
                <div class="pull-right">
                    <button close-modal class="btn btn-outline btn-danger">Fermer </button>
                </div>
            </footer>
        </modal>
    `
})
export class ModalHistoriqueStatutsComponent {

    @Input() statuts: StatutDto;

}
