import { Broadcaster } from './../../core/services/broadcaster.service';
import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { smoothlyMenu } from '../../app.helpers';
import { AuthService } from '../../core/services/auth.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { ReparationService } from 'src/app/reparations/reparations.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TypeDossier } from 'src/app/demandesInternet/models/dossier.model';

declare var jQuery: any;

@Component({
    selector: 'topnavbar',
    templateUrl: './topnavbar.component.html'
})
export class TopNavbarComponent implements OnInit {

    showScanner: boolean = false;

    @ViewChild('scanner')
    scanner: ZXingScannerComponent;
    searchString: string;
    cameras: MediaDeviceInfo[];
    cameraIndex: number = 0;
    submitted: boolean = false;

    constructor(
        private auth: AuthService,
        private reparationService: ReparationService,
        private alert: AlertService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.q) {
                // this.search(params.q);
                this.router.navigate(['/operateurs', `nouvel-appel`], { queryParams: { recherche: params.q } });
            }
        });
    }

    search(input: string) {
        this.submitted = true;
        const regex = /33([0-9]{9})$/;
        if (regex.test(input)) {
            const found = input.match(regex);
            input = '0' + found[1];
        }

        this.reparationService.fastSearch({ numero: input, onlyReparations : false }).then(result => {
            if (result.produits.length) {
                const produit = result.produits[0];
                if (produit.type === TypeDossier.DEMANDE || produit.isSav) {
                    this.router.navigate(['/demandes-internet/view/', produit.dossierId]);
                } else {
                    this.router.navigate(['/reparations/view/', produit._id]);
                }

                this.showScanner = false;
                this.submitted = false;
            } else {
                this.alert.error(`Aucun produit trouvé avec le numéro ou le code barre "${input}"`);
                this.submitted = false;
            }
        });
    }

    camerasFoundHandler(cameras: MediaDeviceInfo[]) {
        this.cameras = cameras;
        const device = cameras[this.cameraIndex];

        this.scanner.formats = [BarcodeFormat.EAN_13];

        this.scanner.device = device;
        this.scanner.scan(device.deviceId);
        this.scanner.torch = false;
        this.scanner.torchCompatible.subscribe((hasTorch) => console.log('torche', hasTorch));

        this.scanner.camerasFound.subscribe((camerasFound) => {
            this.cameras = camerasFound;
            this.scanner.scan(camerasFound[this.cameraIndex].deviceId);
        });
    }

    changeCamera() {
        this.cameraIndex = (this.cameraIndex + 1) % this.cameras.length;

        this.scanner.scan(this.cameras[this.cameraIndex].deviceId);
    }

    toggleLight() {
        this.scanner.torch = !this.scanner.torch;
    }

    camerasNotFoundHandler() {
        this.alert.error('Aucune caméra détectée :(');
    }

    handleQrCodeResult(resultString: string) {
        this.search(resultString);
    }

    scanErrorHandler($event) {
        this.alert.error('Erreur de caméra');
        console.log('scanErrorHandler', $event);
    }

    scanFailureHandler($event) {
        this.alert.error('Fail de caméra');
        console.log('FailureHandler', $event);
    }

    scanCompleteHandler($event) {
        console.log('CompleteHandler', $event);
    }


    toggleNavigation(): void {
        jQuery('body').toggleClass('mini-navbar');
        smoothlyMenu();
    }

    logout() {
        this.auth.logout();
    }

}
