import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-primeng-select',
    template: `
        <div class="primeng-select">
            <p-dropdown [options]="to.options"
                        [formControl]="formControl"
                        [formlyAttributes]="field"
                        [filter]="to.filter"
                        [showClear]="to.showClear"
                        [placeholder]="to.placeholder"
                        [autoWidth]="false"
                        dropdownIcon="fa fa-caret-down">
            </p-dropdown>
        </div>
    `
})
export class FormlyFieldPrimengSelect extends FieldType { }
