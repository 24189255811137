import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-radio',
    template: `
        <div [formGroup]="form">
            <ng-template [ngIf]="!to.inline">
                <div *ngFor="let option of to.options" class="radio gf_radio">
                    <label [for]="'radio_' + id + '_' + i">
                        <input [name]="id"  [id]="'radio_' + id + '_' + i" type="radio" [value]="option.value" [formControl]="formControl" [formlyAttributes]="field">
                        {{ option.label }}
                    </label>
                </div>
            </ng-template>
            <ng-template [ngIf]="to.inline">
                <div class="gf_radio radio-inline"  *ngFor="let option of to.options; let i = index" [attr.data-index]="i">
                    <input [name]="id" type="radio" [id]="'radio_' + id + '_' + i" [value]="option.value" [formControl]="formControl" [formlyAttributes]="field">
                    <label [for]="'radio_' + id + '_' + i">{{ option.label }}</label>
                </div>
            </ng-template>
        </div>
    `,
    styles: [`
        .gf_radio {
            margin-bottom: 5px;
        }
    `]
})
export class FormlyFieldRadio extends FieldType {}
