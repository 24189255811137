import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-panne',
    template: `
        <div class="p-10 pb-0 bg-gray text-bold panne" *ngIf="model[key]">
            <div>{{ model[key].informations.symptomes }}</div>
        </div>
        <div class="fx-between p-10 bg-dark text-white text-bold panne" *ngIf="model[key]">
            <div class="">Prix conseillé : <strong>{{ model[key].commercial.prixTTC }} €</strong></div>
            <div class=" al-right">Panne de type : <span class="capitalize">{{ model[key].commercial.typePanne }}</span></div>
        </div>

        <div class="p-10 pb-0 bg-gray panne" *ngIf="!model[key]">
            <div><i>Aucune d'information supplémentaire disponible</i></div>
        </div>
    `,
})
export class FormlyFieldPanne extends FieldType {
}
