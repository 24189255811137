import { Deserializable } from "../shared/models/deserializable.model";
import { Partenaire } from "../partenaires/models/partenaire.model";

export class Utilisateur implements Deserializable {

    constructor(
        public _id          ?: string,
        public nom          ?: string,
        public prenom       ?: string,
        public email        ?: string,
        public token       ?: string,
        public password    ?: string,
        public confirmation?: string,
        public type        ?: string,
        public modules     ?: string[],

        public partenaire ?: Partenaire,
    ) { }


    public getFullName() {
        return (this.prenom ? this.prenom + ' ' : '') + (this.nom ? this.nom.toUpperCase() : '');
    }


    deserialize(input: any) {
        Object.assign(this, input);

        if (input.partenaire)
            this.partenaire = new Partenaire().deserialize(input.partenaire);
        
        return this;
    }

}
