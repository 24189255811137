import { Deserializable } from "../../shared/models/deserializable.model";

import { HasStatut } from '../../shared/models/statut.model';
import { Panne } from "src/app/pannes/models/panne.model";

export class DevisPanne implements Deserializable {

    constructor(
        public prix?: number,
        public commentaire?: string,
        public panne?: Panne,

        public panneId?: string,
    ) {
    }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.panne !== null) {
            this.panne = new Panne().deserialize(input.panne);
            this.panneId = this.panne._id;  
        }

        return this;
    }

}


export class Devis extends HasStatut implements Deserializable {

    constructor(
        public _id ?: string,
        public pannes ?: DevisPanne[],
        public prixMaximum ?: number,
        public prixTotal ?: number,
        public prixOriginal ?: number,
        public montantPiece ?: number,
        public createdAt ?: Date
    ) { 
        super();
        this.pannes = [];
    }

    getPrix(): number {
        return this.prixTotal;
    }
        
    getTypeText() {
        let ret = 'Devis';
        if (this.statut.identifiant == 'demande_internet')
            ret = 'Estimation internet';
        else if (this.statut.identifiant == 'prise_en_charge')
            ret = 'Estimation magasin';
        
        return ret;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        super.deserialize(input);

        if (input.pannes)
            this.pannes = input.pannes.map(a => new DevisPanne().deserialize(a));

        return this;
    }

}
