import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';

import { BasicGuard, AdminGuard } from './core/auth.guard';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'utilisateurs', loadChildren: './utilisateurs/utilisateurs.module#UtilisateursModule' },
            { path: 'mon-compte', loadChildren: './mon-compte/mon-compte.module#MonCompteModule' },
            { path: 'references', loadChildren: './references/references.module#ReferencesModule' },
            { path: 'partenaires', loadChildren: './partenaires/partenaires.module#PartenairesModule' },
            { path: 'demandes-internet', loadChildren: './demandesInternet/demandesInternet.module#DemandesInternetModule' },
            { path: 'reparations', loadChildren: './reparations/reparations.module#ReparationModule' },
            { path: 'coursier', loadChildren: './coursier/coursier.module#CoursierModule' },
            { path: 'postal', loadChildren: './postal/postal.module#PostalModule' },
            { path: 'pannes', loadChildren: './pannes/pannes.module#PannesModule' },
            { path: 'statistiques', loadChildren: './stats/stats.module#StatsModule' },
            { path: 'operateurs', loadChildren: './operateurs/operateurs.module#OperateursModule' },
            { path: 'pickup', loadChildren: './pickup/pickup.module#PickupModule' },
            { path: 'backmarket', loadChildren: './backmarket/backmarket.module#BackmarketModule' },
            { path: '**', redirectTo: 'page-not-found' }
        ],
        canActivate: [BasicGuard]
    },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
