import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-primeng-multi-select',
    template: `
        <div class="primeng-select">
            <p-multiSelect [options]="to.options"
                           [formControl]="formControl"
                           [formlyAttributes]="field"
                           [maxSelectedLabels]="10"
                           [selectedItemsLabel]="'{0} éléments sélectionnés'"
                           [defaultLabel]="to.placeholder"
                           dropdownIcon="fa fa-caret-down">
            </p-multiSelect>
        </div>
    `
})
export class FormlyFieldPrimengMultiSelect extends FieldType { }
