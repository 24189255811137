import { Utilisateur } from "../../utilisateurs/utilisateur.model";
import { Deserializable } from "./deserializable.model";

export class Note implements Deserializable {

    constructor(
        _id      ?: string,
        texte    ?: string,
        createdAt?: Date,
        updatedAt?: Date,
        user     ?: Utilisateur,
    ) { }

    deserialize(input:any) {
        Object.assign(this, input);

        return this;
    }

}
