import { ParametresService } from './../../core/services/parametres.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { Utilisateur } from '../../utilisateurs/utilisateur.model';
import { StorageService } from '../../core/services/storage.service';
import { AuthService } from '../../core/services/auth.service';
import { Broadcaster } from '../../core/services/broadcaster.service';
import { ReparationService } from 'src/app/reparations/reparations.service';
import { StatutNumbers } from 'src/app/shared/models/statutNumbers.model';
import { DemandesInternetService } from 'src/app/demandesInternet/demandesInternet.service';
import { PrinterType, PrinterService } from 'src/app/core/services/printer.service';
import { ParamKey } from 'src/app/core/services/parametres.service';

declare var jQuery: any;

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.component.html'
})

export class NavigationComponent implements AfterViewInit, OnInit {

    utilisateur: Utilisateur;

    repaCounts: StatutNumbers[] = null;
    demandesCounts: StatutNumbers[] = null;

    $PrinterType = PrinterType;
    $ParamKey = ParamKey;

    parametres: {
        delaisReparation: string,
    };


    constructor(
        private router: Router,
        private storage: StorageService,
        private auth: AuthService,
        private broadcaster: Broadcaster,
        private repaService: ReparationService,
        private demandeService: DemandesInternetService,
        private printerService: PrinterService,
        private parametresService: ParametresService
    ) { }

    async ngOnInit() {
        this.initUtilisateur();
        this.broadcaster.on<string>('utilisateurUpdated').subscribe(() => {
            this.initUtilisateur();
        });

        setTimeout(() => {
            this.update();
        }, 2000);

        this.parametres = {
            delaisReparation:  await this.parametresService.getParametre(ParamKey.DelaisReparation) as string,
        };

        this.broadcaster.on<string>(`parametres:updated:${ParamKey.DelaisReparation}`).subscribe(value => {
            this.parametres.delaisReparation = value;
        });
    }

    initUtilisateur() {
        this.utilisateur = <Utilisateur> this.storage.get('currentUser');
    }

    update() {
        this.repaService.numbers().then(res => {
            this.repaCounts = res;
        });
        this.demandeService.numbers().then(res => {
            this.demandesCounts = res;
        });
    }

    ngAfterViewInit() {
        jQuery('#side-menu').metisMenu();

        if (jQuery('body').hasClass('fixed-sidebar')) {
            jQuery('.sidebar-collapse').slimscroll({
                height: '100%'
            });
        }
    }

    activeRoute(routename: string): boolean {
        return this.router.url.startsWith('/' + routename);
    }

    logout() {
        this.auth.logout();
    }

    setPrinterId(printer: PrinterType) {
        this.printerService.setPrinterId(printer);
    }

    setParametre(key: ParamKey) {
        this.parametresService.setParametre(key);
    }
}
