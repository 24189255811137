import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { Marque } from './models/marque.model';
import { Support } from './models/support.model';
import { Modele } from './models/modele.model';

@Injectable()
export class ReferencesService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/references';
    }

    liste(): Promise<Marque[]> {
        return this.http.get(this.url + '/list')
            .toPromise()
            .then(response => response.json().marques as Marque[])
        ;
    }

    listeWithDeleted(): Promise<Marque[]> {
        return this.http.get(this.url + '/list-with-deleted')
            .toPromise()
            .then(response => response.json().marques as Marque[])
        ;
    }

    // Marque

    ajouterMarque(entity: Marque): Promise<Marque> {
        return this.http.post(this.url + '/marque/add', entity)
            .toPromise()
            .then(response => response.json() as Marque)
            ;
    }

    modifierMarque(entity: Marque): Promise<Marque> {
        return this.http.put(this.url + '/marque/edit/' + entity._id, entity)
            .toPromise()
            .then(response => response.json() as Marque)
            ;
    }

    supprimerMarque(entity: Marque): Promise<Marque> {
        return this.http.delete(this.url + '/marque/remove/' + entity._id)
            .toPromise()
            .then(response => response.json() as Marque)
            ;
    }

    // Support

    ajouterSupport(entity: Support): Promise<Support> {
        return this.http.post(this.url + '/support/add', entity)
            .toPromise()
            .then(response => response.json() as Support)
            ;
    }

    modifierSupport(entity: Support): Promise<Support> {
        return this.http.put(this.url + '/support/edit/' + entity._id, entity)
            .toPromise()
            .then(response => response.json() as Support)
            ;
    }

    supprimerSupport(entity: Support): Promise<Support> {
        return this.http.delete(this.url + '/support/remove/' + entity._id)
            .toPromise()
            .then(response => response.json() as Support)
            ;
    }

    // Marque

    ajouterModele(entity: Modele): Promise<Modele> {
        return this.http.post(this.url + '/modele/add', entity)
            .toPromise()
            .then(response => response.json() as Modele)
            ;
    }

    modifierModele(entity: Modele): Promise<Modele> {
        return this.http.put(this.url + '/modele/edit/' + entity._id, entity)
            .toPromise()
            .then(response => response.json() as Modele)
            ;
    }

    supprimerModele(entity: Modele): Promise<Modele> {
        return this.http.delete(this.url + '/modele/remove/' + entity._id)
            .toPromise()
            .then(response => response.json() as Modele)
            ;
    }

}
