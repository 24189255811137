import { NgModule, forwardRef } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';

import { SharedModule } from '../shared/shared.module';

import { BOOTSTRAP_FORMLY_CONFIG, FIELD_TYPE_COMPONENTS } from './ui-bootstrap.config';

import { FormlyValidationMessageComponent } from './formly.validation-message';
import { ModalFormComponent } from './modal-form.component';

@NgModule({
    declarations: [
        ...FIELD_TYPE_COMPONENTS,
        FormlyValidationMessageComponent,
        ModalFormComponent
    ],
    imports: [
        SharedModule,
        FormlyModule.forRoot(BOOTSTRAP_FORMLY_CONFIG)
    ],
    entryComponents: [
        ModalFormComponent
    ],
    exports: [
        FormlyModule
    ]
})
export class FormlyThemeModule { }
