import { Component } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyFormBuilder } from '@ngx-formly/core';
import { Observable } from 'rxjs/Observable';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { ConfigService } from '../../core/config';
import { StorageService } from '../../core/services/storage.service';
import { AlertService } from '../../core/services/alert.service';
import { FileService } from '../../core/services/file.service';


@Component({
    selector: 'formly-field-file',
    templateUrl: 'file.form.html'
})
export class FormlyFieldFile extends FieldType {
    uploader : FileUploader;
    tmpPath: string = null;

    constructor(
        public builder     : FormlyFormBuilder,
        public config      : ConfigService,
        public storage     : StorageService,
        public alertService: AlertService,
        public fileService : FileService,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        console.log('create', this.model, this.key);

        const url = this.config.api.image + '/upload';
        const token = this.storage.get('token');

        this.uploader = new FileUploader({
            url: url,
            // authToken: 'Bearer ' + token,
            queueLimit: 1,
            allowedFileType: ['image'],
            removeAfterUpload: true
        });

        this.uploader.onAfterAddingFile = (fileItem => {
            fileItem.withCredentials = false;
            const savedPicture = this.model[this.key];
            delete this.model[this.key];
            // TODO ?
            this.fileService.handleFileItem(fileItem, true).then(dataFile => {
                this.tmpPath = dataFile;
                fileItem.upload();
            }).catch(() => {
                fileItem.remove();
                this.model[this.key] = savedPicture;
            });
        });

        this.uploader.onSuccessItem = ((item: FileItem, response: string, status: number) => {
            console.log('res', response);

            var res = JSON.parse(response) as {_id : string};

            this.model[this.key] = res._id;
        });
    }

    remove() {
        const message = 'Êtes-vous sûr ?';
        this.alertService.confirm(message, 'Supprimer l\'image', false, (confirm) => {
            if (confirm) {
                this.alertService.info('Image supprimée!');
                this.model[this.key] = null;
                this.tmpPath = null;
            }
        });
    }
}
