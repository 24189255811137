import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-alert',
    template: `
        <div class="Alert alert alert-{{ type }} {{ autre }}" [class.Alert--noMargin]="noMargin">
            <i *ngIf="icon" [class]="'fa fa-fw fa-2x fa-' + icon"></i>
            <div>
                <ng-content></ng-content>
            </div>
        </div>
    `
})
export class AlertComponent {

    @Input() type: string = 'success';
    @Input() autre: string = '';
    @Input() icon: string;
    @Input() noMargin: boolean;

    constructor() { }

}
