import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[label-required]'
})
export class LabelRequiredDirective implements OnInit {

    constructor(private el: ElementRef) { }

    ngOnInit() {
        this.el.nativeElement.innerHTML += ' <span class="text-danger">*</span>';
    }

}