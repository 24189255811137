import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap';

import { LayoutComponent } from './layout.component';

import { NavigationComponent } from './navigation/navigation.component';
import { TopNavbarComponent } from './topnavbar/topnavbar.component';
import { SharedModule } from '../shared/shared.module';
import { NavigationNotificationComponent } from './navigation/navigation-notification.component';


@NgModule({
  declarations: [
    LayoutComponent,
    NavigationComponent,
    TopNavbarComponent,
    NavigationNotificationComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    RouterModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    LayoutComponent,
    NavigationComponent,
    TopNavbarComponent
  ],
})

export class LayoutModule {}
