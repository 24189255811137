import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { Partenaire } from './models/partenaire.model';

import { HasStatutService } from '../shared/models/statut.model';

@Injectable()
export class PartenairesService implements HasStatutService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/partenaires';
    }

    liste(): Promise<Partenaire[]> {
        return this.http.get(this.url + '/list')
            .toPromise()
            .then(response => {
                var data = response.json().partenaires as Partenaire[];

                var ret = data.map(i => new Partenaire().deserialize(i));

                return ret;
            })
            ;
    }

    info(entity : Partenaire): Promise<Partenaire> {
        return this.http.get(this.url + '/info/' + entity._id)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    ajouter(entity: Partenaire): Promise<Partenaire> {
        return this.http.post(this.url + '/add', entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    modifier(entity: Partenaire): Promise<Partenaire> {
        return this.http.put(this.url + '/edit/' + entity._id, entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    changerStatut(entity: Partenaire, identifiant: string, commentaire: string): Promise<Partenaire> {
        return this.http.put(this.url + '/changer-statut/' + entity._id, { identifiant: identifiant, commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);

                return entity;
            })
            ;
    }


    retirerStatut(entity: Partenaire, commentaire: string): Promise<Partenaire> {
        return this.http.put(this.url + '/retirer-statut/' + entity._id, { commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);


                return entity;
            })
            ;
    }

}
