import { plainToClass } from 'class-transformer';
import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import * as fileSaver from 'file-saver';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { HasStatutService, Statut } from '../shared/models/statut.model';
import { RepaProduit } from './models/RepaProduit.model';
import { Produit, ResolutionReparation, FinReparationData } from '../demandesInternet/models/produit.model';
import { Transaction } from './models/transaction.model';
import { StatutNumbers } from '../shared/models/statutNumbers.model';
import { Postal } from './models/postal.model';
import { ModifierPointRelaiDto } from '../postal/postal.dto';
import { ColisRetour } from '../postal/postal.models';
import { Campagne } from '../shared/models/campagne.model';
import { SavDto, CreateSavDto, CreateSavResponseDto } from './models/sav.dtos';

@Injectable()
export class ReparationService implements HasStatutService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/reparation';
    }

    numbers(): Promise<StatutNumbers[]> {
        return this.http.get(this.url + '/numbers/')
            .toPromise()
            .then(response => response.json().map(r => new StatutNumbers().deserialize(r)));
            ;
    }

    liste(page : number, statut: string): Promise<{ produits : RepaProduit[], maxPage : number }> {
        return this.http.get(this.url + '/list/' + page + (statut ? ('/' + statut) : ''))
            .toPromise()
            .then(response => {
                var req = response.json();

                var data = req.produits as RepaProduit[];

                var ret = data.map(i => new RepaProduit().deserialize(i));

                return { produits : ret, maxPage : req.maxPage };
            })
            ;
    }

    today(): Promise<RepaProduit[]> {
        return this.http.get(this.url + '/today')
            .toPromise()
            .then(response => response.json().map(p => new RepaProduit().deserialize(p)));
            ;
    }

    search(page: number, search): Promise<{ produits: RepaProduit[], maxPage: number }> {
        return this.http.post(this.url + '/search/' + page, search)
            .toPromise()
            .then(response => {
                var res = response.json();
                var data = res.produits as RepaProduit[];

                var ret = data.map(i => new RepaProduit().deserialize(i));

                return { produits: ret, maxPage: res.maxPage };
            })
            ;
    }

    exportExcel(search) {
        return this.http.post(this.url + '/export-excel', search, { responseType: ResponseContentType.Blob })
            .toPromise()
            .then(response => {
                const date    = new Date();
                const time = date.getDate() + '_' + date.getMonth() + '_' + date.getFullYear() + '_' + date.getHours() + date.getMinutes();
                const blob = new Blob([response.blob()], { type: 'application/vnd.ms-excel' });
                fileSaver.saveAs(blob, 'reparations_' + time + '.xlsx');
            })
        ;
    }


    fastSearch(search): Promise<{ produits: RepaProduit[], maxPage: number }> {
        return this.http.post(this.url + '/fast-search', search)
            .toPromise()
            .then(response => {
                var res = response.json();
                var data = res.produits as RepaProduit[];

                var ret = data.map(i => new RepaProduit().deserialize(i));

                return { produits: ret, maxPage: res.maxPage };
            })
            ;
    }

    info(entity: RepaProduit): Promise<RepaProduit> {
        console.log('start infos');
        return this.http.get(this.url + '/info/' + entity._id)
            .toPromise()
            .then(response => { console.log('end infos'); return entity.deserialize(response.json()) })
            ;
    }
    statuts(): Promise<Statut[]> {
        return this.http.get(this.url + '/statuts')
            .toPromise()
            .then(response => response.json().map(s => new Statut().deserialize(s)))
            ;
    }

    campagnes(): Promise<Campagne[]> {
        return this.http.get(`${this.url}/campagnes`)
            .toPromise()
            .then(response => response.json().map(item => plainToClass(Campagne, item)))
        ;
    }


    ajouter(entity: RepaProduit, dossierId: string, produitIds = null): Promise<RepaProduit> {
        if (produitIds)
            entity = Object.assign(entity, produitIds);

        return this.http.post(this.url + '/add/' + dossierId, entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    modifier(entity: RepaProduit, produitIds = null): Promise<RepaProduit> {
        if (produitIds)
            entity = Object.assign(entity, produitIds);

        return this.http.put(this.url + '/edit/' + entity._id, entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    diagnostique(entity: RepaProduit): Promise<RepaProduit> {
        return this.http.put(this.url + '/diagnostique/' + entity._id, entity)
            .toPromise()
            .then(response => { return entity.deserialize(response.json()) })
            ;
    }

    finReparation(entity: RepaProduit, data: FinReparationData): Promise<RepaProduit> {
        return this.http.put(this.url + '/fin-reparation/' + entity._id, data)
            .toPromise()
            .then(response => { return entity.deserialize(response.json()) })
            ;
    }

    changerStatut(entity: RepaProduit | Produit, identifiant: string, commentaire: string): Promise<RepaProduit | Produit> {
        return this.http.put(this.url + '/changer-statut/' + entity._id, { identifiant: identifiant, commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);

                return entity;
            })
            ;
    }

    retirerStatut(entity: RepaProduit, commentaire: string): Promise<RepaProduit> {
        return this.http.put(this.url + '/retirer-statut/' + entity._id, { commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.deserializeStatuts(response.json());

                entity.statut = newStat.statut;
                entity.statuts.push(newStat);


                return entity;
            })
            ;
    }

    payer(dossierId: string, produits: Produit[], transaction: Transaction): Promise<any> {
        return this.http.put(this.url + '/payer/' + dossierId, { produits_id: produits.map(p => p._id), transaction : transaction })
            .toPromise()
            ;
    }

    getColisPostaux(produit: RepaProduit): Promise<Postal> {
        return this.http.get(this.url + '/colis-postaux/' + produit._id)
            .toPromise()
            .then(response => response.json() as Postal)
        ;
    }

    modifierPointRelai(dossierId: string, dto: ModifierPointRelaiDto): Promise<ColisRetour> {
        return this.http.put(`${this.config.api.url}/postal/modifier-point-relai/${dossierId}`, dto)
            .toPromise()
            .then(response => response.json() as ColisRetour)
        ;
    }

    getSav(produit_id: string): Promise<SavDto> {
        return this.http.get(`${this.url}/sav/${produit_id}`)
            .toPromise()
            .then(response => plainToClass(SavDto, response.json() as SavDto))
        ;
    }

    creerSav(produit_id: string, dto: CreateSavDto): Promise<CreateSavResponseDto> {
        return this.http.post(`${this.url}/sav/${produit_id}`, dto)
            .toPromise()
            .then(response => plainToClass(CreateSavResponseDto, response.json() as CreateSavResponseDto))
        ;
    }

}
