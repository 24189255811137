import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

import { Error403Component } from './error403/error403.component';
import { Error404Component } from './error404/error404.component';
import { ConnexionComponent } from './connexion/connexion.component';

@NgModule({
    imports: [
        SharedModule,
        PagesRoutingModule
    ],
    declarations: [
        ConnexionComponent,
        Error403Component,
        Error404Component
    ]
})
export class PagesModule {

}
