import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { Marque } from '../references/models/marque.model';
import * as moment from 'moment';
import { ListeTransactionsTermineesDto, ModePaiementDto, TypeTransactionDto, TypeDossierDto, SearchParamsDto } from './transactions-terminees/transactions-terminees.dtos';
import { plainToClass } from 'class-transformer';
import { StatsAcquisitionDto } from './acquisition/acquisition.dto';
import { OtherDashboardDto } from '../dashboard/dashboard.dtos';
import { TypeDeclenchement, TauxTransformationDto, DeclenchementDto, PeriodePersonnaliseeDto, TauxEnvoiColisDto, TauxDepartementDto, TauxDepotParDistanceDto } from './transformations/transformations.dtos';


export enum TypePeriode {
    MOIS = 'mois',
    SEMAINE = 'semaine',
    JOUR = 'jour',
}

export interface AEncaisserDto {
    global: number;
    postal6Mois: number;
    physique3Mois: number;
}

@Injectable()
export class StatsService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/stats';
    }


    dashboard(date: moment.Moment): Promise<{}> {
        return this.http.get(this.url + '/dashboard/main/' + date.format('DD-MM-YYYY'))
            .toPromise()
            .then(response => response.json())
        ;
    }

    otherDashboard(date: moment.Moment): Promise<OtherDashboardDto> {
        return this.http.get(this.url + '/dashboard/other/' + date.format('DD-MM-YYYY'))
            .toPromise()
            .then(response => response.json() as OtherDashboardDto)
        ;
    }

    recettes(period: TypePeriode, start: Date, nbr: number): Promise<{}> {
        let format = 'DD-MM-YYYY';
        return this.http.get(this.url + '/recettes/' + period + '/' + moment(start).format(format) + '/' + nbr)
            .toPromise()
            .then(response => response.json());
    }

    reparations(): Promise<{}> {
        return this.http.get(this.url + '/reparations')
            .toPromise()
            .then(response => response.json());
    }

    aEncaisser(): Promise<AEncaisserDto> {
        return this.http.get(this.url + '/a-encaisser')
            .toPromise()
            .then(response => response.json());
    }

    async transactionsTerminees(page: number, searchParams: SearchParamsDto): Promise<ListeTransactionsTermineesDto> {
        return this.http.post(`${this.config.api.url}/transactions/transactions-terminees/${page}`, searchParams)
            .toPromise()
            .then(response => response.json() as ListeTransactionsTermineesDto)
        ;
    }

    async acquisition(searchParams: SearchParamsDto): Promise<StatsAcquisitionDto[]> {
        return this.http.post(`${this.url}/acquisition`, searchParams)
            .toPromise()
            .then(response => response.json() as StatsAcquisitionDto[])
        ;
    }

    /**
     * Transformations
     */

    async tauxTransformation(type: TypeDeclenchement | 'all', periode: PeriodePersonnaliseeDto): Promise<TauxTransformationDto[]> {
        return this.http.post(`${this.url}/transformations/taux-transformation/${type}`, periode)
            .toPromise()
            .then(response => response.json() as TauxTransformationDto[])
        ;
    }

    async declenchements(type: TypeDeclenchement | 'all', periode: PeriodePersonnaliseeDto): Promise<DeclenchementDto[]> {
        return this.http.post(`${this.url}/transformations/declenchements/${type}`, periode)
            .toPromise()
            .then(response => response.json() as DeclenchementDto[])
        ;
    }

    async tauxEnvoiColis(periode: PeriodePersonnaliseeDto): Promise<TauxEnvoiColisDto> {
        return this.http.post(`${this.url}/transformations/taux-envoi-colis`, periode)
            .toPromise()
            .then(response => response.json() as TauxEnvoiColisDto)
        ;
    }

    async tauxParDepartement(periode: PeriodePersonnaliseeDto): Promise<TauxDepartementDto[]> {
        return this.http.post(`${this.url}/transformations/taux-par-departement`, periode)
            .toPromise()
            .then(response => response.json() as TauxDepartementDto[])
        ;
    }

    async tauxDepotParDistance(periode: PeriodePersonnaliseeDto): Promise<TauxDepotParDistanceDto> {
        return this.http.post(`${this.url}/transformations/taux-depot-par-distance`, periode)
            .toPromise()
            .then(response => response.json() as TauxDepotParDistanceDto)
        ;
    }

}
