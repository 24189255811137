import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormlyConfig } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-validation-message',
    template: `<i class="fa fa-fw fa-times"></i> {{ errorMessage }}`,
})
export class FormlyValidationMessageComponent {
    @Input() fieldForm: FormControl;
    @Input() field: any;

    constructor(private formlyMessages: FormlyConfig) {}

    get errorMessage() {
        for (const error in this.fieldForm.errors) {
            if (this.fieldForm.errors.hasOwnProperty(error)) {
                let message = this.formlyMessages.getValidatorMessage(error);

                if (this.field.validation && this.field.validation.messages && this.field.validation.messages[error]) {
                    message = this.field.validation.messages[error] as string;
                }

                ['validators', 'asyncValidators'].map(validators => {
                    if (this.field[validators] && this.field[validators][error] && this.field[validators][error].message) {
                        message = this.field.validators[error].message;
                    }
                });

                if (typeof message === 'function') {
                    return message(this.fieldForm.errors[error], this.field);
                }

                return message;
            }
        }
    }
}
