import { Contact } from "../../shared/models/contact.model";
import { DemandeInternet } from "./demandeInternet.model";
import { Note } from "../../shared/models/note.model";
import { Deserializable } from "../../shared/models/deserializable.model";
import { Produit } from "./produit.model";
import { Adresse } from "src/app/shared/models/adresse.model";
import { InformationsClient } from "src/app/shared/models/informationsClient.model";
import { Partenaire } from "src/app/partenaires/models/partenaire.model";
import { Transaction } from "src/app/reparations/models/transaction.model";
import { Params } from "@angular/router";

export enum TypeDossier {
    DEMANDE = 'demande_internet',
    POSTAL = 'postal',
    COURSIER = 'coursier',
    PARTENAIRE = 'partenaire',
    PICKUP = 'pickup'
}


export enum TypeDevice {
    PHONE = 'phone',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

export class Dossier implements Deserializable, InformationsClient {


    private prix = 0;

    constructor(
        public _id?                  : string,
        public contact?              : Contact,
        public adresse?              : Adresse,
        public type ?                : string,
        public isPickup ?            : boolean,
        public numero         ?      : number,
        public token          ?      : string,
        public ip             ?      : String,
        public localisation?         : { regionName: string, city: string },
        public userAgent      ?      : string,
        public deviceType            ?: TypeDevice,
        public demandeInternet?      : DemandeInternet,
        public commentaires?         : Note[],
        public produits?             : Produit[],
        public createdAt ?           : Date,
        public partenaire ?          : Partenaire,
        public express ?             : boolean,
        public paiementsReparations ?: Transaction[],
        public paiementFraisEnvoi ?: Transaction,
        public urlParams ?: Params,
        public isSav?: boolean,
        public codePromoPhysique?: { code: string, montant: number }
    ) {
        this.contact = new Contact();
        this.adresse = new Adresse();
        this.demandeInternet = new DemandeInternet();
        this.produits = [];
    }

    getEstimation(): number {
        var prix = 0;

        this.produits.forEach(produit => {
            prix += produit.getEstimation();
        });

        return prix;
    }

    isPaye(): boolean {
        let isPaye = true;

        this.produits.forEach(p => {
            if (!p.isPaye())
                isPaye = false;
        });

        return isPaye;
    }

    isRestitue() : boolean {
        let isRestitue = true;
        this.produits.forEach(p => {
            if (p.statut.identifiant != 'restitue_client')
                isRestitue = false;
        });

        return isRestitue;
    }

    getSolde(): number {
        let prix = 0;
        this.produits.forEach(p => prix += p.getSolde());

        return prix;
    }

    getTypeColor(type = null): string {
        var ret: string;

        if (type === null)
            type = this.type;

        switch (type) {
            case TypeDossier.COURSIER:
                ret = 'info';
                break;
            case TypeDossier.POSTAL:
                ret = 'navy';
                break;
            case TypeDossier.PARTENAIRE:
                ret = 'primary';
                break;

            default:
                ret = 'primary';
                break;
        }

        return ret;
    }


    getTypeText(type = null): string {
        var ret: string;

        if (type === null)
            type = this.type;

        switch (type) {
            case TypeDossier.COURSIER:
                ret = 'Coursier';
                break;
            case TypeDossier.POSTAL:
                ret = 'Postal';
                break;
            case TypeDossier.PARTENAIRE:
                ret = 'Partenaire';
                break;
            case TypeDossier.PICKUP:
                ret = 'Pickup';
                break;

            default:
                ret = 'Demande internet';
                break;
        }

        return ret;
    }

    //

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.contact)
            this.contact = new Contact().deserialize(input.contact);

        if (input.adresse)
            this.adresse = new Adresse().deserialize(input.adresse);

        if (input.demandeInternet)
            this.demandeInternet = new DemandeInternet().deserialize(input.demandeInternet);

        if (input.produits)
            this.produits = input.produits.map(a => new Produit().deserialize(a))

        if (input.commentaires)
            this.commentaires = input.commentaire.map(a => new Note().deserialize(a))

        if (input.partenaire)
            this.partenaire = new Partenaire().deserialize(input.partenaire);

        if (input.paiementFraisEnvoi)
            this.paiementFraisEnvoi = new Transaction().deserialize(input.paiementFraisEnvoi);

        if (input.paiementsReparations)
            this.paiementsReparations = input.paiementsReparations.map(t => new Transaction().deserialize(t));

        return this;
    }
}