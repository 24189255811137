import { Component, OnInit } from '@angular/core';
import { detectBody } from '../app.helpers';
import { Broadcaster } from '../core/services/broadcaster.service';

declare var jQuery: any;

@Component({
    selector: 'layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['./layout.component.scss'],
    host: {
        '(window:resize)': 'onResize()'
    }
})
export class LayoutComponent implements OnInit {

    fixed: boolean = false;
    showNavigation: boolean = true;
    showTopNavBar: boolean = true;

    constructor(private broadcaster: Broadcaster) { }

    public ngOnInit(): any {
        this.broadcaster.on<string>('fixLayout').subscribe(() => {
            this.fixed = true;
        });
        this.broadcaster.on<string>('unfixLayout').subscribe(() => {
            this.fixed = false;
        });
        this.broadcaster.on<string>('enableOperateurLayout').subscribe(() => {
            this.showNavigation = false;
            this.showTopNavBar = false;
        });
        this.broadcaster.on<string>('disableOperateurLayout').subscribe(() => {
            this.showNavigation = true;
            this.showTopNavBar = true;
        });
        detectBody();
    }

    public onResize() {
        detectBody();
    }

}
