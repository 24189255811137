import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-primeng-autocomplete',
    template: `
        <div class="primeng-autocomplete">
            <p-autoComplete [formControl]="formControl"
                            [formlyAttributes]="field"
                            [placeholder]="to.placeholder"
                            [suggestions]="to.suggestions"
                            (completeMethod)="to.recherche($event)"
                            [field]="to.field"
                            [forceSelection]="true"
                            [multiple]="to.multiple"
                            [dropdown]="to.dropdown">
            </p-autoComplete>
        </div>
    `
})
export class FormlyFieldPrimengAutocomplete extends FieldType { }
