export enum ModeReceptionSav {
    Chronopost = 'chronopost',
    Colissimo = 'colissimo',
    DepotMagasin = 'depot_magasin',
    Pickup = 'pickup'
}

export class CreateSavDto {
    produits: [ { [property: string]: boolean } ];
    commentaire: string;
    modeReception: ModeReceptionSav;
    modeRetourPostal: ModeLivraison;
    contact: {
        nom: string;
        prenom: string;
        email: string;
        telephone: string;
    };
    adresse?: {
        adresse1: string;
        adresse2: string;
        cp: string;
        ville: string;
    };
    identifiantPointRelaiRetour?: string;
}

export enum ModeLivraison {
    PointRelai = 'point_relai',
    Domicile   = 'domicile'
}

export class CreateSavResponseDto {
    dossier: {
        _id: string;
        numero: number;
    };
}

export class SavDto {
    dossier: {
        contact: {
            nom: string;
            prenom: string;
            email: string;
            telephone: string;
        };
        adresse?: {
            adresse1: string;
            adresse2: string;
            cp: string;
            ville: string;
        };
        identifiantPointRelaiRetour?: string;
        produits: {
            _id: string;
            numero: string;
            marque: string;
            support: string;
            modele: {
                image: string;
                designation: string;
            },
            current: boolean;
        }[]
    };
    parent: {
        _id: string;
        numero: number;
    };
    enfants: {
        _id: string;
        numero: number;
    }[];
}
