import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Interceptor, InterceptedRequest, InterceptedResponse } from 'ng2-interceptors';

import { StorageService } from './storage.service';

@Injectable()
export class HttpInterceptorService implements Interceptor {

    constructor(
        private storage: StorageService,
        private router: Router
    ) { }

    public interceptBefore(request: InterceptedRequest): InterceptedRequest {
        // Do whatever with request: get info or edit it

        const headers = new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
        const token = this.storage.get('token');
        if (token) {
            headers.set('Authorization', 'Bearer ' + token);
        }

        request.options.headers = headers;

        return request;
        /*
          You can return:
            - Request: The modified request
            - Nothing: For convenience: It's just like returning the request
            - <any>(Observable.throw("cancelled")): Cancels the request, interrupting it from the pipeline, and calling back 'interceptAfter' in backwards order of those interceptors that got called up to this point.
        */
    }

    public interceptAfter(response: InterceptedResponse): InterceptedResponse {
        // Do whatever with response: get info or edit it

        if (response.response.status === 401) {
            this.storage.remove('token');
            this.storage.remove('currentUser');
            this.storage.set('returnUrl', this.router.url);
            window.location.href = '/connexion';
        }

        return response;
        /*
          You can return:
            - Response: The modified response
            - Nothing: For convenience: It's just like returning the response
        */
    }
}
