import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
/* App Root */
import { AppComponent } from './app.component';

/* Eager Loaded Feature Modules */
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { LayoutModule } from './layout/layout.module';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';
import { FormlyThemeModule } from './formly/formly-theme.module';

import { MomentModule } from 'angular2-moment';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { NgxSpinnerModule } from 'ngx-spinner';

import * as drilldown from 'highcharts/modules/drilldown.src';
import * as map from 'highcharts/modules/map.src';
import * as exporting from 'highcharts/modules/exporting.src';
// import { ModalFormComponent } from './formly/modal-form.component';

registerLocaleData(localeFr);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    LayoutModule,
    PagesModule,
    FormlyThemeModule,
    MomentModule,
    ChartModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    // ModalFormComponent
  ],
  entryComponents: [
    // ModalFormComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ drilldown, exporting, map ] }
  ]
})
export class AppModule { }
