import { Component, HostListener, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'modal',
    template: `
        <div class="modal inmodal" [ngClass]="{'modal--large': size === 'large'}" id="myModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content animated bounceInRight">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" close-modal>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">
                            <ng-content select="header"></ng-content>
                        </h4>
                    </div>
                    <div class="modal-body">
                        <ng-content></ng-content>
                    </div>
                    <div class="modal-footer">
                        <ng-content select="footer"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .modal {
            display: block
        }
        .modal-content {
            /*top: 100px;*/
        }
        .modal-title {
            font-weight: normal;
        }
        .modal--large .modal-dialog {
            max-width: 800px;
        }
    `]
})
export class ModalComponent {

    @Input() size: 'normal'|'large' = 'normal';

}
