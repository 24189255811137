import { Utilisateur } from "../../utilisateurs/utilisateur.model";
import { Deserializable } from "../../shared/models/deserializable.model";
import { HasStatut } from "../../shared/models/statut.model";

export class DemandeInternet extends HasStatut implements Deserializable {

    constructor(
        public ouvertures ?: { date ?: Date, user ?: Utilisateur }[],
        public rappel?: { enabled: boolean, expired: boolean, expiresAt: Date }
    ) { 
        super();
    }

    deserialize(input: any) {
        Object.assign(this, input);
        super.deserialize(input);

        if (input.ouvertures)
            this.ouvertures = input.ouvertures.map(a => {
                return {
                    date : a.date,
                    user : new Utilisateur().deserialize(a.user),
                }
            });

        return this;
    }

}
