import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import { Connexion } from '../pages/connexion/connexion.model';
import { Utilisateur } from './utilisateur.model';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class UtilisateursService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/users';
    }

    liste(): Promise<Utilisateur[]> {
        return this.http.get(this.url + '/list')
            .toPromise()
            .then(response => response.json().map(u => new Utilisateur().deserialize(u)));
            ;
    }

    ajouter(utilisateur: Utilisateur): Promise<any> {
        return this.http.post(this.url + '/add', utilisateur).toPromise();
    }

    modifier(utilisateur: Utilisateur): Promise<any> {
        return this.http.put(this.url + '/edit/' + utilisateur._id, utilisateur).toPromise();
    }

    remove(utilisateur: Utilisateur): Promise<any> {
        return this.http.delete(this.url + '/delete/' + utilisateur._id).toPromise();
    }

    getModules(): Promise<{ value: string, label: string }[]> {
        return this.http.get(this.url + '/modules')
            .toPromise()
            .then(response => response.json())
        ;
    }

}
