import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-ngx-chips',
    template: `
        <tag-input [formControl]="formControl"
                   [formlyAttributes]="field"
                   [placeholder]="to.placeholder"
                   [editable]="to.editable"
                   [modelAsStrings]="true"
                   [errorMessages]="to.errorMessages"
                   [validators]="to.validators"
                   [addOnBlur]="true"
                   [addOnPaste]="true"
                   [secondaryPlaceholder]="to.secondaryPlaceholder">
        </tag-input>
    `,
    styles: [``]
})
export class FormlyFieldNgxChips extends FieldType { }
