import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-big-loading',
    template: `
    <div class="overlay" *ngIf="loading">
        <div class="spinner-block">
            <div class="sk-spinner sk-spinner-three-bounce">
                <div class="sk-bounce1"></div>
                <div class="sk-bounce2"></div>
                <div class="sk-bounce3"></div>
            </div>
        </div>
    </div>
    `,
    styles: [`
        .overlay {
            position: fixed;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            background-color: rgba(40, 40, 40, 0.22);
            z-index: 99999999;
        }

        .overlay .spinner-block {
            text-align: center;
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 200px;
            height: 40px;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            padding-top: 10px;
        }

        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @-moz-keyframes spin {
            0% {
                -moz-transform: rotate(0deg);
            }
            100% {
                -moz-transform: rotate(360deg);
            }
        }

        @-ms-keyframes spin {
            0% {
                -ms-transform: rotate(0deg);
            }
            100% {
                -ms-transform: rotate(360deg);
            }
        }

        @-o-keyframes spin {
            0% {
                -o-transform: rotate(0deg);
            }
            100% {
                -o-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `],
})
export class BigLoadingComponent {
    @Input() loading: boolean;
}
