import { Directive, OnInit, OnChanges, Input, ElementRef, ErrorHandler } from '@angular/core';

import { ConfigService } from '../../core/config';

declare var $: any;

@Directive({
    selector: '[serverImage]'
})
export class ServerImageDirective implements OnInit, OnChanges {

    @Input('serverImage') serverImage: string; 

    constructor(
        public el: ElementRef,
        public config: ConfigService
    ) { }

    ngOnInit() {
        this.showImage()
    }

    ngOnChanges() {
        this.showImage()
    }

    showImage() {
        var src;

        if (this.serverImage.substr(0, 4) == "http")
        {
            src = this.serverImage;
        } else {
            src = this.config.api.image + '/' + this.serverImage + '?' + Date.now();
        }

        $(this.el.nativeElement).attr('src', src);
    }

}