import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { StorageService } from './services/storage.service';

@Injectable()
export class BasicGuard implements CanActivate {

    constructor(private router: Router, private storage: StorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.storage.get('currentUser')) {
            return true;
        } else {
            this.storage.set('returnUrl', state.url);
            this.router.navigate(['/connexion']);
            return false;
        }
    }
}

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private router: Router, private storage: StorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.storage.get('currentUser');
        if (currentUser.type !== 'ADMINISTRATEUR') {
            this.router.navigate(['/access-denied']);
            return false;
        } else {
            return true;
        }
    }
}
