import { Broadcaster } from './core/services/broadcaster.service';
import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ModalService } from './core/services/modal.service';
import { ModalPlaceholderDirective } from './core/directives/modal-placeholder.directive';
import * as moment from 'moment';
import * as locales from 'moment/min/locales';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ng-template modal-placeholder></ng-template>
        <ngx-spinner
            bdOpacity="1"
            bdColor="rgba(230,126,34,0.35)"
            size="medium"
            color="#e67e22"
            type="ball-fussion"
            [fullScreen]="true"
        >
        </ngx-spinner>
    `
})
export class AppComponent implements AfterViewInit, OnInit {
    toasterConfig: any;

    @ViewChild(forwardRef(() => ModalPlaceholderDirective)) modalPlaceholder: ModalPlaceholderDirective;

    constructor(
        private modalService: ModalService,
        private spinner: NgxSpinnerService
    ) { }

    ngAfterViewInit() {
        this.modalService.modalPlaceholder = this.modalPlaceholder;
    }

    ngOnInit() {
        $(document).on('click', '[href="#"]', e => e.preventDefault());
        moment.locale('fr');
    }

}