import { Directive, HostListener } from '@angular/core';

import { ModalService } from '../../core/services/modal.service';

@Directive({
    selector: '[close-modal]'
})
export class CloseModalDirective {

    constructor(
        public modalService: ModalService
    ) { }

    @HostListener('click') onClick() {
        this.modalService.close();
    }

}
