import { MatDatepicker } from '@angular/material';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-input',
    template: `
        <input *ngIf="!to.maskedInput && !to.datePicker" [type]="type" [maxlength]="to.maxlength || 524288" [formControl]="formControl" class="form-control"
               [ngClass]="{rtl: to.rtl}" [formlyAttributes]="field" [ngClass]="{'form-control-danger': showError}">
        <input *ngIf="to.maskedInput" [maskedInput]="to.maskedInput" [type]="type" [maxlength]="to.maxlength || 524288" [formControl]="formControl" class="form-control"
               [ngClass]="{rtl: to.rtl}" [formlyAttributes]="field" [ngClass]="{'form-control-danger': showError}">

        <ng-container *ngIf="to.datePicker">
            <input [matDatepicker]="picker" [type]="hidden" [formControl]="formControl" class="form-control" #input (keypress)="keypress($event)"
                [ngClass]="{rtl: to.rtl}" [formlyAttributes]="field" [ngClass]="{'form-control-danger': showError}" (click)="picker.open()" (dateChange)="dateChange($event)">

            <mat-datepicker [touchUi]="true" #picker></mat-datepicker>
        </ng-container>
    `,
    styles: [`
        input.rtl {
            direction: rtl;
        }
    `]
})
export class FormlyFieldInput extends FieldType {

    @ViewChild('picker') picker: MatDatepicker<Date>;
    @ViewChild('input') input: ElementRef;

    get type() {
        return this.to.type || 'text';
    }

    keypress(event) {
        this.picker.open();
        return false;
    }

    dateChange(event) {
        const value = event.value.format(this.to.dateFormat || 'DD/MM/YYYY');
        // this.formControl.setValue(value, { emitEvent: true });
        // this.input.nativeElement.value = value;
    }
}
