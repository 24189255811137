import { Component, Input } from '@angular/core';
import { Produit } from 'src/app/demandesInternet/models/produit.model';
import { InformationsClient } from '../models/informationsClient.model';

@Component({
    selector: 'infos-client',
    template: `
        <div class="ibox-content">
            <strong>{{ infos.contact.getFullName() }}</strong>
            <p>
                <br /><a [href]="'tel:' + infos.contact.telephone">{{ infos.contact.telephone|phone }}</a>
                <br /><a [href]="'mailto:' + infos.contact.email">{{ infos.contact.email }}</a>
            </p>
            <div *ngIf="infos.adresse && infos.adresse.adresseFormated().length && !small">
                <h4>Adresse</h4>
                <p [innerHtml]="infos.adresse.adresseFormated()">
                </p>
                <p>
                    <a [href]="infos.adresse.googleMapUrl()" target="_blank">
                        <img [src]="infos.adresse.googleMapImageUrl('300x300')" alt="" />
                    </a>
                </p>
            </div>
            <div *ngIf="!small">
                <div *ngIf="infos.localisation && infos.localisation.regionName">
                    <h4>Loc. par ip</h4>
                    <p *ngIf="infos.localisation && infos.localisation.regionName">
                        {{ infos.localisation.regionName }}
                        <small *ngIf="infos.localisation.city">({{ infos.localisation.city }})</small>
                        <br /><small *ngIf="infos.ip">ip : {{ infos.ip }}</small>
                    </p>
                </div>
                <p [title]="infos.userAgent" data-toggle="tooltip">
                    Appareil :
                    <br /><i class="fa fa-2x" [ngClass]="{
                        'fa-mobile-alt' : infos.deviceType == 'phone',
                        'fa-tablet-alt' : infos.deviceType == 'tablet',
                        'fa-desktop' : infos.deviceType == 'desktop'
                    }"></i>
                    <br /><small>{{ infos.userAgent }}</small>
                </p>
                
            </div>
        </div>
    `
})
export class InfosClientComponent {

    @Input() infos: InformationsClient;
    @Input() small : boolean = false;

    constructor() { }

}
