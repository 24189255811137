import * as moment from 'moment';

export class Campagne {
    _id: string;
    nom: String;
    createdAt: Date;

    toString() {
        return `${moment(this.createdAt).format('DD/MM/YYYY')} : ${this.nom}`;
    }
}
