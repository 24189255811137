import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string) {
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value);
        } else {
            return null;
        }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

}
