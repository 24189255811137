import { Directive, Input, ElementRef, OnInit } from '@angular/core';

declare var $:any;

@Directive({
    selector: '[maskedInput]'
})
export class MaskedInputDirective implements OnInit {

    @Input('maskedInput') mask: string;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        $(this.el.nativeElement).mask(this.mask);
    }

}