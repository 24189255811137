import { Deserializable } from "./deserializable.model";

enum Civilite {
    MONSIEUR = 'monsieur',
    MADAME = 'madame'
}

enum ContactType {
    LEGAL = 'contact_legal',
    RESPONSABLE = 'responsable_reparation',
}

export class Contact implements Deserializable {

    constructor(
        public _id      ?: string,
        public nom      ?: string,
        public prenom   ?: string,
        public civilite ?: Civilite,
        public email    ?: string,
        public telephone?: string,
        public type     ?: ContactType,
    ) { }

    public getFullName() {
        return (this.prenom ? this.prenom + ' ' : '') + (this.nom ? this.nom.toUpperCase() : '');
    }


    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
