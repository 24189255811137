import { Deserializable } from "../../shared/models/deserializable.model";

export enum ModePaiement {
    Lydia = 'lydia',
    Stripe = 'stripe',
    Especes = 'especes',
    CarteBancaire = 'carte_bancaire',
    Cheque = 'cheque',
    Alma = 'alma'
}

export enum TypePaiement {
    Reparation = 'reparation',
    FraisEnvoi = 'frais_envoi',
    Autre = 'autre'
}

export class TransactionLydia implements Deserializable {

    constructor(
        public createdAt  ?: Date,
        public requestId  ?: string,
        public requestUuid?: string,
        public mobileUrl  ?: string,
        public canceled   ?: boolean,
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);

        return this;
    }

}

export class Transaction implements Deserializable {

    constructor(
        public _id ?         : string,
        public designation  ?: string,
        public montant      ?: number,
        public token        ?: string,
        public datePaiement? : Date,
        public lydia?        : TransactionLydia,
        public lydiaHistory? : TransactionLydia[],
        public modesPaiement? : string[],
        public createdAt?    : Date,
        public editedAt?     : Date,
        public type?         : TypePaiement,
        public canceled?     : boolean,
        public recipient?    : string,
        public redirectUrl?  : string,
        public paiements?    : {
            especes?: number,
            carte_bancaire?: number,
            cheque?: number,
            lydia?: number,
            stripe?: number,
            alma?: number,
        },
        // pour le form 
        public enableModePaiement ?: {

        }
    ) { 
        this.montant = 0;
        this.modesPaiement = [];
        this.enableModePaiement = {}; // 
        this.paiements = {};
    }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.lydia)
            this.lydia = new TransactionLydia().deserialize(input.lydia);

        if (input.lydiaHistory)
            this.lydiaHistory = input.lydiaHistory.map(l => new TransactionLydia().deserialize(l));

        return this;
    }

    getPaiementIcone(moyen) {
        let ret = '';

        switch (moyen) {
            case 'especes':
                ret = 'money-bill-alt';
                break;
            case 'carte_bancaire':
                ret = 'credit-card';
                break;
            case 'cheque':
                ret = 'money-check-alt';
                break;
            case 'lydia':
                ret = 'lyft';
                break;
            case 'stripe':
                ret = 'lyft';
                break;

            default:
                break;
        }

        return ret;
    }

}
