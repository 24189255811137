import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    templateUrl: './error404.component.html',
})
export class Error404Component {

    constructor(private location: Location) { }

    retour() {
        this.location.back();
    }
}
