import { Directive, OnInit, OnChanges, Input, ElementRef, ErrorHandler } from '@angular/core';

import { ConfigService } from '../../core/config';

declare var $: any;

@Directive({
    selector: '[serverLink]'
})
export class ServerLinkDirective implements OnInit, OnChanges {

    @Input('serverLink') serverLink: string; 

    constructor(
        public el: ElementRef,
        public config: ConfigService
    ) { }

    ngOnInit() {
        this.showImage()
    }

    ngOnChanges() {
        this.showImage()
    }

    showImage() {
        var src;

        if (this.serverLink.substr(0, 4) == "http")
        {
            src = this.serverLink;
        } else {
            src = this.config.api.image + '/' + this.serverLink + '?' + Date.now();
        }

        $(this.el.nativeElement).attr('href', src);
    }

}