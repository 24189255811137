import { Marque } from "../../references/models/marque.model";
import { Support } from "../../references/models/support.model";
import { Modele } from "../../references/models/modele.model";
import { Deserializable } from "../../shared/models/deserializable.model";
import { HasStatut } from "../../shared/models/statut.model";
import { Devis } from "src/app/reparations/models/devis.model";
import { Panne } from "src/app/pannes/models/panne.model";

export enum TypeTicket {
    PEC_MAGASIN       = 'pec_magasin',
    PEC_CLIENT        = 'pec_client',
    PEC_PRODUIT       = 'pec_produit',
    FINAL_MAGASIN     = 'final_magasin',
    FINAL_CLIENT      = 'finale_client',
    ETIQUETTE_PRODUIT = 'etiquette_produit'
}

export enum ResolutionReparation {
    REPARE      = 'repare',
    NON_ACCEPTE = 'non_accepte',
    IMPOSSIBLE  = 'reparation_impossible',
}


export enum ResolutionReparationText {
    repare                = 'Console réparée',
    non_accepte           = 'Réparation non acceptée',
    reparation_impossible = 'Réparation impossible',
}

export enum TypeProduitV1 {
    CONSOLE = 'console',
    TELEPHONE = 'telephone',
    TABLETTE = 'tablette',
    ORDINATEUR_BUREAU = 'ordinateur_bureau',
    ORDINATEUR_PORTABLE = 'ordinateur_portable',
    ACCESSOIRE = 'accessoire',
    AUTRE = 'autre'
}

export class FinReparationData {
    resolution ?: ResolutionReparation;
    commentaire ?: string;
}

export class ProduitInformations implements Deserializable {
    constructor(
        public numero_serie ?: string,
        public couleur ?: string,
        public signe_distinctif ?: string,
        public jeu ?: string,
        public accessoire ?: string,
        public materiel_prete ?: string,
        public code ?: string,
        public avec_jeu ?: string,
        public avec_accessoire ?: string,
        public avec_materiel_prete ?: string,
        public avec_code ?: string,

    ) {

    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}


export class ProduitPanne implements Deserializable {
    constructor(
        public panne?: Panne,
        public commentaire?: string,
        public prix?: number,

        public panneId?: string,
    ) { }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.panne) {
            this.panne = new Panne().deserialize(input.panne);
            this.panneId = this.panne._id;
        }

        return this;
    }

}


export class Produit extends HasStatut implements Deserializable {

    constructor(
        public _id ?: string,
        public marque ?: Marque,
        public support?: Support,
        public modele ?: Modele,
        public numero?: string,
        public codeBarre?: string,
        public informations?: ProduitInformations,
        public devis ?: Devis[],
        public preAcceptationClient?: boolean,
        public coutEnvisageMaximum?: number,
        public isExpresse?: boolean,

        public prixTotal ?: number,

        public pannes ?: ProduitPanne[],
        public newDevis ?: Devis,
        public produitV1?: {
            type?: TypeProduitV1,
            marque?: string,
            modele?: string,
        }
    ) {
        super();
    }

    getModeleName(): string {
        if (this.modele)
            return this.modele.designation;
        return this.produitV1.modele;
    }

    //
    deserialize(input: any) {
        Object.assign(this, input);
        super.deserialize(input);

        if (input.devis) {
            this.devis = input.devis.map(item => new Devis().deserialize(item));
            // console.log('bf', this.devis);
            this.devis.sort((a, b) => -1);
            // console.log('af', this.devis);
        }

        this.fillPannes();

        if (input.informations)
            this.informations = new ProduitInformations().deserialize(input.informations);

        return this;
    }


    getCurrentDevis(): Devis {
        return this.devis.find(d => d.statut.identifiant != 'annule');
    }

    getDevisEstimation() {
        let devis = this.devis.find(d => d.statuts.find(s => s.statut.identifiant == 'demande_internet') != null);

        if (!devis)
            devis = this.devis.find(d => d.statuts.find(s => s.statut.identifiant == 'pris_en_charge') != null);

        if (!devis && this.devis.length)
            return this.devis[0];
        
        return devis;
    }

    getEstimation(): number {
        let devis = this.getDevisEstimation();
        if (!devis)
            return NaN;
        return devis.prixTotal;
    }


    getPrix(): number {
        let prix = 0;
        let devis = this.getDevisActifs();

        devis.forEach(d => {
            prix += d.getPrix();
        });

        return prix;
    }

    getDevis(statuts: string[] = null) {
        if (!statuts)
            return this.devis;

        return this.devis.filter(d => statuts.indexOf(d.statut.identifiant) != -1);
    }

    public getSolde(): number {

        let prix = 0;
        let devis = this.getDevisActifs();

        Number(devis.forEach(d => {
            if (d.statut.identifiant != 'paye')
                prix += d.getPrix()
        }));

        return prix;
    }


    getDevisActifs() {
        return this.devis.filter(d => ['annule', 'refuse'].indexOf(d.statut.identifiant) == -1);
    }

    isPaye():boolean {
        return ((this.devis.find(d => ['annule', 'paye', 'refuse'].indexOf(d.statut.identifiant) == -1)) === undefined);
    }

    fillPannes() {
        let devis = this.getCurrentDevis();

        if (!devis) return;

        this.pannes = devis.pannes.map(p => {
            return new ProduitPanne().deserialize({
                panne: p.panne,
                commentaire : p.commentaire,
                prix : p.prix,
            });
        });

        this.prixTotal = devis.prixTotal;
    }


}
