import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { Panne, FamillePanne } from './models/panne.model';
import { Modele } from '../references/models/modele.model';
import { Marque } from '../references/models/marque.model';

@Injectable()
export class PanneService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/pannes';
    }


    ajouter(entity: Panne): Promise<Panne> {
        return this.http.post(this.url + '/add', entity)
            .toPromise()
            .then(response => new Panne().deserialize(response.json()))
            ;
    }

    modifier(entity: Panne): Promise<Panne> {
        return this.http.put(this.url + '/edit/' + entity._id, entity)
            .toPromise()
            .then(response => new Panne().deserialize(response.json()))
            ;
    }


    ajouterFamille(entity: FamillePanne): Promise<Panne> {
        return this.http.post(this.url + '/familles/add', entity)
            .toPromise()
            .then(response => new FamillePanne().deserialize(response.json()))
            ;
    }

    modifierFamille(entity: FamillePanne): Promise<Panne> {
        return this.http.put(this.url + '/familles/edit/' + entity._id, entity)
            .toPromise()
            .then(response => new FamillePanne().deserialize(response.json()))
            ;
    }

    supprimer(entity: Panne): Promise<{}> {
        return this.http.delete(this.url + '/remove/' + entity._id)
            .toPromise()
            .then(response => response.json())
            ;
    }

    getFromMarque(marque: Marque): Promise<Panne[]> {
        return this.http.get(this.url + '/marque/' + marque._id)
            .toPromise()
            .then(response => {
                var data = response.json() as Panne[];

                var ret = data.map(i => new Panne().deserialize(i));

                return ret;
            })
            ;
    }


    famillesPannes(): Promise<FamillePanne[]> {
        return this.http.get(this.url + '/familles')
            .toPromise()
            .then(response => response.json().map(fp => new FamillePanne().deserialize(fp)))
            ;
    }

    getFromModele(entityId): Promise<Panne[]> {
        return this.http.get(this.url + '/modele/' + entityId)
            .toPromise()
            .then(response => {
                var data = response.json() as Panne[];

                var ret = data.map(i => new Panne().deserialize(i));

                return ret;
            })
            ;
    }

    getFromModeleAndDossier(modeleId: string, dossierId: string): Promise<Panne[]> {
        return this.http.get(this.url + '/modele/' + modeleId + '/' + dossierId)
            .toPromise()
            .then(response => {
                var data = response.json() as Panne[];

                var ret = data.map(i => new Panne().deserialize(i));

                return ret;
            })
            ;
    }
}
