import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-wrapper-label',
    template: `
        <label [attr.for]="id" class="control-label" *ngIf="to.label">
            {{ to.label }}
            <span *ngIf="to.required" class="text-danger">*</span>
        </label>
        <div *ngIf="to.labelBox" class="label label-xs pull-right" [ngClass]="'label-' + to.labelBox.color">
            {{ to.labelBox.text }}
        </div>
        <ng-container #fieldComponent></ng-container>
    `,
})
export class FormlyWrapperLabel extends FieldWrapper {
    @ViewChild('fieldComponent', {read: ViewContainerRef}) fieldComponent: ViewContainerRef;
}
