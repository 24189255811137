import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../config';

import 'rxjs/add/operator/toPromise';
import { Email } from 'src/app/demandesInternet/models/email.model';
import { Sms } from 'src/app/demandesInternet/models/sms.model';
import { plainToClass } from 'class-transformer';
import { ListeEmailsEnvoyesDto } from 'src/app/stats/emails-envoyes/emails-envoyes.dtos';

@Injectable()
export class NotificationService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/notifications';
    }

    getAllEmails(page: number, type: 'all' | 'relances'): Promise<ListeEmailsEnvoyesDto> {
        return this.http.get(this.url + '/all-emails/' + type + '/' + page)
            .toPromise()
            .then(response => plainToClass(ListeEmailsEnvoyesDto, response.json() as ListeEmailsEnvoyesDto))
        ;
    }

    getEmails(dossierId: string): Promise<Email[]> {
        return this.http.get(this.url + '/emails/' + dossierId)
            .toPromise()
            .then(response => response.json().map(r => new Email().deserialize(r)))
            ;
    }


    sendEmails(emailId: string) {
        return this.http.put(this.url + '/renvoyer/email/' + emailId, {})
            .toPromise()
            .then()
            ;
    }

    getSms(dossierId: string): Promise<Email[]> {
        return this.http.get(this.url + '/sms/' + dossierId)
            .toPromise()
            .then(response => response.json().map(r => new Sms().deserialize(r)))
            ;
    }


    sendSms(smsId: string) {
        return this.http.put(this.url + '/renvoyer/sms/' + smsId, {})
            .toPromise()
            .then()
            ;
    }
}

