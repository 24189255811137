export class Commentaire {
    _id: string;
    texte: string;
    createdAt: Date;
    updatedAt: Date;
    user: {
        _id: string;
        nom: string;
        prenom: string;
    };
}
