import {Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Broadcaster } from '../core/services/broadcaster.service';
import { ConfigService } from '../core/config';

@Component({
    template: `
        <modal>
            <header>{{ title }}</header>

            <div class="row">
                <form class="formly" role="form" novalidate id="modalForm" [formGroup]="form" (submit)="canSubmit() && submit(model)">
                    <formly-form [model]="model"
                                    [fields]="fields"
                                    [form]="form"
                                    [options]="{ formState: { model: model } }">
                    </formly-form>
                </form>
            </div>

            <div [hidden]="!config.debug">
                <pre>{{ model | json }}</pre>
            </div>

            <footer>
                <div *ngIf="showRequired" class="pull-left">
                    <span class="text-red">*</span> obligatoire
                </div>
                <div class="pull-right">
                    <button close-modal class="btn btn-danger">{{ cancelLabel }}</button>
                    <button type="submit" form="modalForm" class="btn btn-primary ml-10" [disabled]="!canSubmit()">
                        <i class="fa fa-check"></i> &nbsp;&nbsp;{{ submitLabel }}
                    </button>
                </div>
            </footer>
        </modal>
    `
})
export class ModalFormComponent implements OnDestroy {

    @Input() fields: any;
    @Input() model: any;
    @Input() title: string;
    @Input() submitLabel = 'Valider';
    @Input() cancelLabel = 'Annuler';
    @Input() showRequired = true;

    @Output() formUpdated = new EventEmitter<{ model: any, callback: (submitted: boolean) => void }>();
    @Output() destroyed = new EventEmitter<void>();

    form: FormGroup = new FormGroup({});
    submitted = false;

    constructor(
        private broadcaster: Broadcaster,
        public config: ConfigService,
    ) { }

    ngOnDestroy() {
        this.destroyed.emit();
    }

    triggerValidation(form: any) {
        // if (form.constructor.name === 'FormGroup') {
        //     Object.keys(form.controls).map((controlName) => {
        //         this.triggerValidation(form.get(controlName));
        //     });
        // } else {
        //     form.markAsTouched({onlySelf: true});
        // }
    }

    canSubmit() {
        // this.triggerValidation(this.form);
        return !this.submitted && this.form.status === 'VALID';
    }

    submit(model) {
        const self = this;
        this.submitted = true;
        this.formUpdated.emit({
            model: model,
            callback: function(submitted: boolean) {
                self.submitted = submitted;
                if (submitted) {
                    self.broadcaster.broadcast('closeModal');
                }
            }
        });
    }

}
