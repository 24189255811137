import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Error403Component } from './error403/error403.component';
import { Error404Component } from './error404/error404.component';
import { ConnexionComponent } from './connexion/connexion.component';

const routes: Routes = [
    { path: 'connexion', component: ConnexionComponent },
    { path: 'page-not-found', component: Error404Component },
    { path: 'access-denied', component: Error403Component },
    { path: '**', redirectTo: 'page-not-found' }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [ RouterModule ]
})
export class PagesRoutingModule { }
