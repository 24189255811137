import { Deserializable } from "../../shared/models/deserializable.model";
import { Modele } from "../../references/models/modele.model";

export enum TypePanne {
    A = 'a', // consoles
    B = 'b',
    C = 'c', // accessoires
    D = 'd',
}

export class Panne implements Deserializable {
    constructor(
        public modeleId?: string,
        public supportId?: string,
        public marqueId?: string,

        public _id?: string,
        public informations?: Informations,
        public commercial?: Commercial,
        public complements?: Complements,
        public position?: number
        // public pieces?: 
        // public commercial?: 
        // public partenaires?: 

    ) { }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.informations)
            this.informations = new Informations().deserialize(input.informations);

        if (input.commercial)
            this.commercial = new Commercial().deserialize(input.commercial);

        if (input.complements)
            this.complements = new Complements().deserialize(input.complements);

        return this;

    }
}

export class FamillePanne implements Deserializable {
    constructor(
        public _id         ?: string,
        public designation ?: string,
        public image       ?: string,
        public description ?: string,
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class Informations implements Deserializable {
    constructor(
        public _id?: string,
        public questionFormulaire?: string,
        public designation?: string,
        public familles?: FamillePanne[],
        public temps?: number,
        public symptomes?: string,
        public preconisations?: string,
        public microSoudure?: boolean,
        public explicationsReparation?: string,
        public instructionsEnvoiPostal?: string,

        // Pour le form
        public famillesIds ?: string[],
    ) {
        this.familles = [];
    }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.familles)
            this.familles = input.familles.map(f => new FamillePanne().deserialize(f));

        this.famillesIds = this.familles.map(f => f._id);

        return this;
    }
}


export class Commercial implements Deserializable {
    constructor(
        public _id?: string,
        public prixTTC?: number,
        public dureeGarantieMois?: number,
        public typePanne ?: TypePanne,
        public precisionPrix?: string,
        public surcoutPostalPointRelai?: number,
        public surcoutPostalDomicile?: number,
    ) { }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}

export class Complements implements Deserializable {
    constructor(
        public accessoires?: string,
        public preAcceptation?: boolean,
        public envoiExpress?: boolean,
        public multiPannes?: boolean,
        public reparationExpress?: boolean
    ) { }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}


