import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    templateUrl: './error403.component.html',
})
export class Error403Component {

    constructor(private location: Location) { }

    retour() {
        this.location.back();
    }
}
