import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../core/services/auth.service';
import { StorageService } from '../../core/services/storage.service';
import { AlertService } from '../../core/services/alert.service';

import { ConfigService } from '../../core/config';

import { Connexion } from './connexion.model';

// const swal = require('sweetalert');

@Component({
  templateUrl: './connexion.component.html',
})
export class ConnexionComponent implements OnInit {

    loginForm: FormGroup;

    submitted: Boolean = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private routerService: Router,
        private storageService: StorageService,
        private alertService: AlertService,
    ) { }

    ngOnInit() {
        const lastEmail = this.storageService.get('lastEmail');
        this.loginForm = this.fb.group({
            email: [lastEmail, [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    submit($event) {
        $event.preventDefault();
        this.submitted = true;
        const self = this;
        this.authService.login(this.loginForm.value).then(function() {
            const returnUrl = self.storageService.get('returnUrl');
            if (returnUrl) {
                self.storageService.remove('returnUrl');
                self.routerService.navigate([returnUrl]);
            } else {
                self.routerService.navigate(['']);
            }
        }).catch(function(error) {
            if (error.status === 409) {
                self.alertService.error('Veuillez vérifier vos identifiants.', 'Erreur lors de la connexion');
            }
            self.submitted = false;
        });
    }

}
