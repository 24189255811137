import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

export enum PrinterType { BonTransport = 'bonTransport', Ticket = 'ticket', EtiquetteProduit = 'etiquetteProduit', A4 = 'a4' }

@Injectable()
export class PrinterService {

    constructor(
        private alertService: AlertService,
        private storageService: StorageService
    ) { }

    async getPrinterIdAndSetIfNotExist(printer: PrinterType): Promise<string> {
        return await this.getPrinterId(printer) || await this.setPrinterId(printer);
    }

    async getPrinterId(printer: PrinterType): Promise<string> {
        const printerId = await this.storageService.get(this.getStorageKey(printer));

        return printerId;
    }

    private getStorageKey(printer: PrinterType): string {
        return `${printer}_printerId`;
    }

    async setPrinterId(printer: PrinterType): Promise<string> {
        const printerId    = await this.getPrinterId(printer);
        const message      = this.getPrinterIdPromptMessage(printer);
        const newPrinterId = await this.alertService.prompt(message, printerId);

        if (newPrinterId) {
                this.storageService.set(this.getStorageKey(printer), newPrinterId);
            return newPrinterId;
        }
        else {
            return printerId;
        }
    }

    private getPrinterIdPromptMessage(printer: PrinterType): string {
        const messages = [
            { printer: PrinterType.BonTransport, message: `ID de l'imprimante bon de transport` },
            { printer: PrinterType.Ticket, message: `ID de l'imprimante ticket` },
            { printer: PrinterType.EtiquetteProduit, message: `ID de l'imprimante à étiquettes pour les produits` },
            { printer: PrinterType.A4, message: `ID de l'imprimante à feuilles A4` }
        ];

        return messages.find(item => item.printer === printer).message;
    }

}
