import { Injectable } from '@angular/core';

import * as swal from 'sweetalert';
import swal2 from 'sweetalert2';

import * as jQuery from 'jquery';

@Injectable()
export class AlertService {

    constructor() { }

    private showAlert(text: string, title: string, type: string, colorName: string) {
        swal({
            title: title || '',
            text: text,
            type: type,
            html: true,
        });
    }

    error(text: string, title?: string) {
        this.showAlert(text, title, 'error', 'danger');
    }

    success(text: string, title?: string) {
        this.showAlert(text, title, 'success', 'success');
    }

    info(text: string, title?: string) {
        this.showAlert(text, title, 'info', 'info');
    }

    async prompt(message: string, inputValue?: string) {
        const { value } = await swal2.fire({
            title: message,
            input: 'text',
            inputValue,
            showCancelButton: true,
            inputValidator: (val: string) => {
                if (!val) {
                    return 'Champ obligatoire';
                }
            }
        });
        return value;
    }

    confirm(text: string, title?: string, dontCloseOnConfirm?: boolean, callback?: any) {
        swal({
            title: title || '',
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            closeOnConfirm: !dontCloseOnConfirm,
            closeOnCancel: true,
            html: true
        }, (confirm) => {
            jQuery('body').removeClass('stop-scrolling');

            if (callback)
                return callback(confirm);
        });
    }

}
