import { Contact } from "./contact.model";
import { Adresse } from "./adresse.model";
import { Deserializable } from "./deserializable.model";

export class InformationsClient implements Deserializable {

    constructor(
        public _id?            : string,
        public contact?: Contact,
        public adresse?: Adresse,
        public ip             ?: String,
        public localisation?: { regionName: string, city: string },
        public userAgent      ?: string,
    ) {
        this.contact = new Contact();
        this.adresse = new Adresse();
    }

    //

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.contact)
            this.contact = new Contact().deserialize(input.contact);

        if (input.adresse)
            this.adresse = new Adresse().deserialize(input.adresse);

        return this;
    }
}
