import { Injectable, Type, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { ModalPlaceholderDirective } from '../directives/modal-placeholder.directive';
import { ModalFormComponent } from '../../formly/modal-form.component';

import { Broadcaster } from './broadcaster.service';

declare var $: any;

@Injectable()
export class ModalService {

    modalPlaceholder: ModalPlaceholderDirective;

    constructor(private broadcaster: Broadcaster) {
        this.broadcaster
            .on<void>('closeModal')
            .subscribe(() => this.close())
        ;
    }

    open(componentFactoryResolver: ComponentFactoryResolver, component: Type<any>, attrs?: any): any {
        const componentFactory = componentFactoryResolver.resolveComponentFactory(component);
        const viewContainerRef = this.modalPlaceholder.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);

        for (const attr in attrs) {
            if (attrs.hasOwnProperty(attr)) {
                componentRef.instance[attr] = attrs[attr];
            }
        }

        $('body').addClass('modal-open');

        return componentRef.instance;
    }

    openForm(componentFactoryResolver: ComponentFactoryResolver, attrs?: any): any {
        const componentFactory = componentFactoryResolver.resolveComponentFactory(ModalFormComponent);
        const viewContainerRef = this.modalPlaceholder.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);

        for (const attr in attrs) {
            if (attrs.hasOwnProperty(attr)) {
                componentRef.instance[attr] = attrs[attr];
            }
        }

        $('body').addClass('modal-open');

        return componentRef.instance;
    }

    close(): void {
        this.modalPlaceholder.viewContainerRef.clear();
        $('body').removeClass('modal-open');
    }

}
