import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { FileItem } from 'ng2-file-upload';
import { ConfigService } from '../config';

declare const $: any;

interface CheckFileOptions {
    maxSize?: number;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
};

@Injectable()
export class FileService {

    constructor(
        private alertService: AlertService,
        private config: ConfigService
    ) { }

    private handleError(alertError: Boolean, message: string, title?: string): {title?: string, message: string} {
        if (alertError) {
            this.alertService.error(message, title);
        }
        return {title: title, message: message};
    }

    handleFileItem(fileItem: FileItem, alertError: Boolean = true, options?: CheckFileOptions): Promise<string> {
        return new Promise((resolve, reject) => {
            options = options || {};
            options.maxSize = options.maxSize || this.config.fileChecks.maxSize || undefined;

            const file: File = fileItem._file;
            if (file.type.startsWith('image')) {
                options.minWidth   = options.minWidth  || this.config.fileChecks.minWidth  || undefined;
                options.maxWidth   = options.maxWidth  || this.config.fileChecks.maxWidth  || undefined;
                options.minHeight  = options.minHeight || this.config.fileChecks.minHeight || undefined;
                options.maxHeight  = options.maxHeight || this.config.fileChecks.maxHeight || undefined;
            }

            const self = this;

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(e) {
                const size = e['total'] / 1000000;
                if (options.maxSize && size > options.maxSize) {
                    const message = `Max filesize: ${options.maxSize} Mo`;
                    return reject(self.handleError(alertError, message, 'File too big!'));
                }

                if (file.type.startsWith('image')) {
                    let message;
                    $('<img/>').attr('src', e.currentTarget['result']).on('load', function() {
                        if (options.minWidth && this.width < options.minWidth || options.minHeight && this.height < options.minHeight) {
                            if (options.minWidth && options.minHeight) {
                                message = `Image should be at least ${options.minWidth} x ${options.minHeight} pixels`;
                            } else if (options.minWidth) {
                                message = `Image should be at least ${options.minWidth} pixels width`;
                            } else {
                                message = `Image should be at least ${options.minHeight} pixels height`;
                            }
                            return reject(self.handleError(alertError, message, 'Image too small!'));
                        }
                        if (options.maxWidth && this.width > options.maxWidth || options.maxHeight && this.height > options.maxHeight) {
                            if (options.maxWidth && options.maxHeight) {
                                message = `Image should be less than ${options.maxWidth} x ${options.maxHeight} pixels`;
                            } else if (options.maxWidth) {
                                message = `Image should be less than ${options.maxWidth} pixels width`;
                            } else {
                                message = `Image should be less than ${options.maxHeight} pixels height`;
                            }
                            return reject(self.handleError(alertError, message, 'Image too big!'));
                        }
                        return resolve(e.currentTarget['result']);
                    });
                } else {
                    return resolve(e.currentTarget['result']);
                }
            };
        });
    }

}
