import { Type } from 'class-transformer';
import { Email } from 'src/app/demandesInternet/models/email.model';

export class ListeEmailsEnvoyesDto {
    @Type(() => Email)
    transactions: Email[];

    maxPage: number;
    total: number;
}
