import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'ibox-tools',
    template: `
        <div class="ibox-tools dropdown">
            <a (click)="toggle($event)" #link>
                <i class="fa fa-chevron-up"></i>
            </a>
        </div>
    `
})
export class IboxToolsComponent implements AfterViewInit {
    @ViewChild('link') link: ElementRef;
    @Output() toggleOpen = new EventEmitter<{ isOpen : boolean }>();
    @Input() opened: boolean = true;

    isOpen:boolean = true;

    constructor() { }
    
    ngAfterViewInit() {
        console.log(this.link);

        if (!this.opened)
            this.toggleView(this.link.nativeElement);
    }

    toggleView(elem: Element) {
        let element = $(elem);

        var hpanel = element.closest('div.ibox');
        var icon = (element.hasClass('fa') ? element : element.find('.fa'));
        var body = hpanel.find('div.ibox-content');
        var footer = hpanel.find('div.ibox-footer');

        console.log('icon', icon);

        body.slideToggle(300);
        footer.slideToggle(200);
        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        hpanel.toggleClass('panel-collapse');

        setTimeout(() => {
            hpanel.resize();
            hpanel.find('[id^=map-]').resize();
        }, 50);

        this.isOpen = !this.isOpen;
        this.toggleOpen.emit({ isOpen: this.isOpen });
    }

    toggle(event:MouseEvent) {
        const element = event.srcElement;

        this.toggleView(element);
    }

}
