import { Deserializable } from 'src/app/shared/models/deserializable.model';

export class Email implements Deserializable {
    constructor(
        public _id          ?: string,
        public dateEnvoi    ?: Date,
        public identifiant  ?: string,
        public sujet        ?: string,
        public destinataire ?: string,
        public status?: string,
        public open?: boolean,
        public clicked?: boolean,
        public dossier?: { _id: string, numero: number, contact: string }
    ) {}

    public deserialize(input: any) {
        Object.assign(this, input);

        return this;
    }
}
