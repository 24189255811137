import { Pipe, PipeTransform } from '@angular/core';
/*
 * Transforme un numéro de téléphone au bon format.
 * Usage:
 *   value | phone
 * Example:
 *   {{ 0123344556 | phone }}
 *   Transforme en: 01 23 34 45 56
*/
@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(value: string): string {
        if (value.startsWith('+33')) {
            value = value.replace('+33', '0');
        }
        if (value.length === 10) {
            return value.replace(/(.{2})/g, '$1 ');
        }
        return value;
    }
}
