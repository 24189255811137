import { Component, Input } from '@angular/core';

enum TypeLoading {
    Pacman = 'pacman',
    Flickr = 'flickr'
}

@Component({
    selector: 'loading',
    template: `
        <div class="text-center">
            <img *ngIf="type === $TypeLoading.Pacman" class="Pacman" src="assets/images/loading/Pacman-1s-200px.gif">
            <img *ngIf="type === $TypeLoading.Flickr" class="Pacman" src="assets/images/loading/Flickr-1s-200px.gif">

            <!-- <div class="spinner"></div> -->
        </div>
    `,
    styles: [`
        img.Pacman {
            height: 100px;
        }
        .spinner {
            width: 40px;
            height: 40px;
            margin: 100px auto;
            background-color: #333;

            border-radius: 100%;
            -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
            animation: sk-scaleout 1.0s infinite ease-in-out;
        }

        @-webkit-keyframes sk-scaleout {
            0% { -webkit-transform: scale(0) }
            100% {
                -webkit-transform: scale(1.0);
                opacity: 0;
            }
        }

        @keyframes sk-scaleout {
            0% {
                -webkit-transform: scale(0);
                transform: scale(0);
            } 100% {
                -webkit-transform: scale(1.0);
                transform: scale(1.0);
                opacity: 0;
            }
        }
    `]
})
export class LoadingComponent {

    @Input() type: TypeLoading = TypeLoading.Pacman;
    $TypeLoading = TypeLoading;
    types: string[] = Object.values(TypeLoading);

    constructor() {
        if (this.type && this.types.indexOf(this.type) === -1) {
            this.type = null;
        }
        this.type = this.type || this.getRandomType();
    }

    getRandomType(): TypeLoading {
        const n = Math.floor(Math.random() * (this.types.length - 1));

        return this.types[n] as TypeLoading;
    }
}
