import { Utilisateur } from '../../utilisateurs/utilisateur.model';

import 'rxjs/add/operator/toPromise';

import { Deserializable } from "../models/deserializable.model";


export class Statut implements Deserializable {
    constructor(
        public _id?: string,
        public identifiant?: string,
        public nom?: string,
        public commentaire?: string,
        public type?: string,
        public showBouton?: boolean,
        public suivant?: { identifiant: string, texteBouton: string, type: string }[],
    ) {
        this.showBouton = true;
    }

    getSuivantsFromColor(type: string): { identifiant: string, texteBouton: string, type: string }[] {
        if (!this.suivant) return [];
        return this.suivant.filter(i => i.type === type);
    }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.suivant) {
            this.suivant = input.suivant;
        }

        return this;
    }
}

export class HasStatut implements Deserializable {

    public statut?: Statut;
    public statuts?: { createdAt: Date, createdBy: Utilisateur, statut: Statut }[];
    public lastStatutUpdate?: Date;

    constructor() {}

    deserializeStatuts(item) {
        return {
            createdBy: new Utilisateur().deserialize(item.createdBy),
            createdAt: item.createdAt,
            statut: new Statut().deserialize(item.statut),
            commentaire: item.commentaire
        };
    }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.statut)
            this.statut = new Statut().deserialize(input.statut);

        if (input.statuts)
            this.statuts = input.statuts.map(item => this.deserializeStatuts(item));

        return this;
    }

}

export interface HasStatutService {
    changerStatut(entity: HasStatut, identifiant: string, commentaire: string): Promise<HasStatut>;
    retirerStatut(entity: HasStatut, commentaire: string): Promise<HasStatut>;
}
