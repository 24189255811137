import { ConfigOption } from '@ngx-formly/core';
import { FormlyWrapperAddons } from './wrappers/addons';
import { TemplateDescription } from './run/description';
import { TemplateValidation } from './run/validation';
import { TemplateAddons } from './run/addon';
import {
    FormlyFieldInput,
    FormlyFieldCheckbox,
    FormlyFieldRadio,
    FormlyFieldSelect,
    FormlyFieldPrimengMultiSelect,
    FormlyFieldPrimengSelect,
    FormlyFieldPrimengAutocomplete,
    FormlyFieldTextArea,
    FormlyFieldMultiCheckbox,
    FormlyFieldSeparator,
    FormlyFieldRepeat,
    FormlyFieldRepeatTab,
    FormlyFieldFile,
    FormlyFieldWell,
    FormlyFieldPanne,
} from './types/types';
import {
    FormlyWrapperLabel,
    FormlyWrapperDescription,
    FormlyWrapperValidationMessages,
    FormlyWrapperFieldset,
} from './wrappers/wrappers';
import {FormlyFieldNgxChips} from './types/ngx-chips';

export const FIELD_TYPE_COMPONENTS = [
    // types
    FormlyFieldInput,
    FormlyFieldCheckbox,
    FormlyFieldRadio,
    FormlyFieldSelect,
    FormlyFieldPrimengMultiSelect,
    FormlyFieldPrimengSelect,
    FormlyFieldPrimengAutocomplete,
    FormlyFieldTextArea,
    FormlyFieldMultiCheckbox,
    FormlyFieldSeparator,
    FormlyFieldNgxChips,
    FormlyFieldRepeat,
    FormlyFieldRepeatTab,
    FormlyFieldFile,
    FormlyFieldWell,
    FormlyFieldPanne,
    
    // wrappers
    FormlyWrapperLabel,
    FormlyWrapperDescription,
    FormlyWrapperValidationMessages,
    FormlyWrapperFieldset,
    FormlyWrapperAddons,
];

export function requiredValidation(err, field) { return 'Champ obligatoire'; }
export function emailValidation(err, field) { return 'Adresse e-mail incorrecte'; }
export function minlengthValidation(err) { return `Longueur minimale ${err.requiredLength} caractères`; }
export function maxlengthValidation(err) { return `Longueur maximale ${err.requiredLength} caractères`; }

export const BOOTSTRAP_FORMLY_CONFIG: ConfigOption = {
    types: [
        {
            name: 'input',
            component: FormlyFieldInput,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'checkbox',
            component: FormlyFieldCheckbox,
            wrappers: ['fieldset'],
        },
        {
            name: 'radio',
            component: FormlyFieldRadio,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'select',
            component: FormlyFieldSelect,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'primeng-multi-select',
            component: FormlyFieldPrimengMultiSelect,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'primeng-select',
            component: FormlyFieldPrimengSelect,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'primeng-autocomplete',
            component: FormlyFieldPrimengAutocomplete,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'ngx-chips',
            component: FormlyFieldNgxChips,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'textarea',
            component: FormlyFieldTextArea,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'multicheckbox',
            component: FormlyFieldMultiCheckbox,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'separator',
            component: FormlyFieldSeparator,
        },
        {
            name: 'repeat',
            component: FormlyFieldRepeat,
            // wrappers: ['fieldset', 'label'],
        },
        {
            name: 'repeat-tab',
            component: FormlyFieldRepeatTab,
            // wrappers: ['fieldset', 'label'],
        },
        {
            name: 'file',
            component: FormlyFieldFile,
            wrappers: ['fieldset', 'label'],
        },
        {
            name: 'well',
            component: FormlyFieldWell,
        },
        {
            name: 'panne',
            component: FormlyFieldPanne,
        }
    ],
    validationMessages: [
        { name: 'required', message: requiredValidation },
        { name: 'email', message: emailValidation },
        { name: 'minlength', message: minlengthValidation },
        { name: 'maxlength', message: maxlengthValidation },
    ],
    wrappers: [
        {name: 'label', component: FormlyWrapperLabel},
        {name: 'description', component: FormlyWrapperDescription},
        {name: 'validation-message', component: FormlyWrapperValidationMessages},
        {name: 'fieldset', component: FormlyWrapperFieldset},
        {name: 'addons', component: FormlyWrapperAddons},
    ],
    manipulators: [
        {class: TemplateDescription, method: 'run'},
        {class: TemplateValidation, method: 'run'},
        {class: TemplateAddons, method: 'run'},
    ],
};


