import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-repeat',
    template: `
        <div *ngFor="let field of field.fieldGroup; let i = index;">
            <div class="col-12">    
                <formly-group [model]="model[i]"
                                [field]="field"
                                [options]="options"
                                [form]="formControl">
                </formly-group>
            </div>
            <div class="al-right pr-20">
                <a class="text-danger" (click)="remove(i)"><i class="fa fa-times"></i>&nbsp;&nbsp;Supprimer</a>
            </div>
            <hr>
        </div>
        <div class="text-center">
            <button class="btn btn-primary btn-sm" type="button" (click)="add()"><i class="fa fa-plus"></i>&nbsp;&nbsp;{{ to.btnText }}</button>
        </div>
    `,
})
export class FormlyFieldRepeat extends FieldArrayType implements OnInit {
    constructor(builder: FormlyFormBuilder) {
        super(builder);
    }

    ngOnInit() {
        this.to.btnText = this.to.btnText || 'Ajouter';
    }
}
