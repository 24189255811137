import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from './storage.service';

import { Utilisateur } from '../../utilisateurs/utilisateur.model';
import { Connexion } from '../../pages/connexion/connexion.model';
import { ConfigService } from '../config';
import { Http } from '@angular/http';
import { plainToClass } from "class-transformer";


@Injectable()
export class AuthService {

    url: string;

    constructor(
        private storage: StorageService,
        private router: Router,
        private config: ConfigService,
        private http: Http,

    ) {
        this.url = this.config.api.url + '/auth';
    }

    login(model: Connexion): Promise<Utilisateur> {
        const self = this;

        return this.http.post(this.url + '/token', model)
            .toPromise()
            .then(response => {
            var data = response.json() as { user: Object, token: string};

            let user:Utilisateur = plainToClass(Utilisateur, data['user']);

            self.storage.set('currentUser', user);
            self.storage.set('lastEmail', user.email);
            self.storage.set('token', data['token']);
            return user;
        });
    }

    logout() {
        this.router.navigate(['/connexion']);
        this.storage.remove('currentUser');
        this.storage.remove('lastEmail');
    }

}
