import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {

    public api: any;
    public debug: boolean;
    public fileChecks: { maxSize?: number, minWidth?: number, minHeight?: number, maxWidth?: number, maxHeight?: number };

    static paiement = {
        tva: 0.20
    }

    static map = {
        googleMapsApiKey: 'AIzaSyCODpMibZC4kWJoll_K55lyZ8zI_9vdPsM',
    }

    constructor() {

        this.debug = false;

        this.api = {
            url: 'https://dev-vince.api.gamefix.fr/intra',
            image: 'https://dev-vince.api.gamefix.fr/common/fichiers',
            frontUrl: 'https://dev-vince.gamefix.fr',
        };

        this.fileChecks = {
            maxSize: 4,
            minWidth: 0
        };

        
    }

}