import { Contact } from "../../shared/models/contact.model";
import { Adresse } from "../../shared/models/adresse.model";
import { Note } from "../../shared/models/note.model";
import { Societe } from "./societe.model";
import { Deserializable } from "../../shared/models/deserializable.model";

import { HasStatut } from '../../shared/models/statut.model' 

export class Partenaire extends HasStatut implements Deserializable {

    constructor(
        public _id ?: string,
        public nom?: string,
        public contacts?: Contact[],
        public editedAt?: Date,
        public societe?: Societe,
        public adresseDepot?: Adresse,
        public adresseEnlevement?: Adresse,
        public notes?: Note[],
    ) { 
        super();
        this.societe = new Societe();
    }

    deserialize(input: any) {
        Object.assign(this, input);
        super.deserialize(input);

        if (input.societe)
            this.societe = new Societe().deserialize(input.societe);

        return this;
    }

}
