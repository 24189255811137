import { Deserializable } from "../../shared/models/deserializable.model";
import { Produit } from "../../demandesInternet/models/produit.model";

import { Contact } from "src/app/shared/models/contact.model";
import { InformationsClient } from "src/app/shared/models/informationsClient.model";
import { Dossier, TypeDevice } from "src/app/demandesInternet/models/dossier.model";
import { Partenaire } from "src/app/partenaires/models/partenaire.model";
import { Adresse } from "src/app/shared/models/adresse.model";
import { Transaction } from "./transaction.model";
import { Params } from "@angular/router";

export class RepaProduit extends Produit implements Deserializable, InformationsClient {

    constructor(
        public _id?                  : string,
        public partenaire ?          : Partenaire,
        public dossierId?            : string,
        public type ?                : string,
        public isPickup ?            : boolean,
        public contact?              : Contact,
        public adresse?              : Adresse,
        public ip?                   : String,
        public localisation?         : { regionName: string, city: string },
        public userAgent?            : string,
        public deviceType?           : TypeDevice,
        public reparationsAssociees ?: RepaProduit[],
        public token ?               : string,
        public paiementsReparations ?: Transaction[],
        public paiementFraisEnvoi ?  : Transaction,
        public urlParams?: Params,
        public dateEstimeeDiagnostic?: Date,
        public isHorsDelai?: Date,
        public isRestitue?: boolean,
        public isSav?: boolean,
    ) { 
        super();
    }

    getTypeColor() : string {
        return new Dossier().getTypeColor(this.type);
    }
    getTypeText() : string {
        return new Dossier().getTypeText(this.type);
    }

    deserialize(input: any) {
        Object.assign(this, input);
        super.deserialize(input);

        if (input.reparationsAssociees)
            this.reparationsAssociees = input.reparationsAssociees.map(a => new RepaProduit().deserialize(a));

        if (input.contact)
            this.contact = new Contact().deserialize(input.contact);

        if (input.adresse)
            this.adresse = new Adresse().deserialize(input.adresse);

        if (input.partenaire)
            this.partenaire = new Partenaire().deserialize(input.partenaire);

        if (input.paiementsReparations)
            this.paiementsReparations = input.paiementsReparations.map(t => new Transaction().deserialize(t));


        if (input.paiementFraisEnvoi)
            this.paiementFraisEnvoi = new Transaction().deserialize(input.paiementFraisEnvoi);

        return this;
    }

}
