import { Deserializable } from "./deserializable.model";
import { ConfigService } from "src/app/core/config";

export class Adresse implements Deserializable {

    constructor(
        public _id           ?: string,
        public adresse1      ?: string,
        public adresse2      ?: string,
        public adresse3      ?: string,
        public ville         ?: string,
        public cp            ?: string,
        public coordonneesGps?: Number[],
    ) { }

    adresseFormated(sep = null) {
        var ret = "";

        if (sep === null) sep = " ";

        if (this.adresse1)
            ret += this.adresse1 + sep;

        if (this.adresse1 && this.adresse2 && sep == ' ')
            ret += "<br />";

        if (this.adresse2)
            ret += this.adresse2 + sep;

        if (this.adresse3 && this.adresse2 && sep == ' ')
            ret += "<br />";

        if (this.adresse3)
            ret += this.adresse3 + sep;

        if ((this.cp || this.ville) && (this.adresse1 || this.adresse2 || this.adresse3) && sep == ' ')
            ret += '<br />';
        if (this.cp)
            ret += this.cp + sep;
        if (this.ville)
            ret += this.ville;

        return ret;
    }

    googleMapImageUrl(size: string, zoom: number = 10): string {
        let center = this.adresseFormated('+');

        center += '+FRANCE';

        return 'https://maps.googleapis.com/maps/api/staticmap?center=' + center + '&zoom=' + zoom + '&size=' + size + '&maptype=roadmap&key=' + ConfigService.map.googleMapsApiKey;
    }

    googleMapUrl(): string {
        let center = this.adresseFormated('+');

        center += '+FRANCE';

        return 'https://www.google.fr/maps?q=' + center;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
