import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-well',
    template: `
        <div class="well p-20 bg-orange text-white text-bold">
            {{ model[key] }}
        </div>
    `,
})
export class FormlyFieldWell extends FieldType {
}
