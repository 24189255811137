import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { Utilisateur } from '../../utilisateurs/utilisateur.model';
import { StorageService } from '../../core/services/storage.service';
import { AuthService } from '../../core/services/auth.service';
import { Broadcaster } from '../../core/services/broadcaster.service';
import { ReparationService } from 'src/app/reparations/reparations.service';
import { StatutNumbers } from 'src/app/shared/models/statutNumbers.model';

declare var jQuery: any;

@Component({
    selector: 'navigation-notification',
    template: `
        <span class="label label-xs pull-right" [ngClass]="'label-' + color">
            {{ number }}
        </span>
    `
})
export class NavigationNotificationComponent implements OnInit {
    @Input() color : string;
    @Input() statuts : string[];
    @Input() data : StatutNumbers[];

    number: number = 0;

    ngOnInit() {
        this.data.forEach(d => {
            if (this.statuts.indexOf(d.statut.identifiant) != -1)
                this.number += d.count;
        })
    }
}
