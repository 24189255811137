import { Component } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-separator',
    template: `
        <div class="hr-line-dashed"></div>
    `,
    styles: [`
        .hr-line-dashed {
            border-top: 1px dashed #e7eaec;
            height: 1px;
            margin: 20px 0;
            margin-top: 0;
        }
    `]
})
export class FormlyFieldSeparator extends FieldType {
    get to() {
        return {};
    }
    get templateOptions() {
        return {};
    }
    static createControl(model: any, field: FormlyFieldConfig): AbstractControl {
        return new FormControl(
            { value: model ? 'on' : undefined, disabled: field.templateOptions.disabled },
            field.validators ? field.validators.validation : undefined,
            field.asyncValidators ? field.asyncValidators.validation : undefined,
        );
    }
}
