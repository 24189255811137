import { StatutComponent } from './components/statut.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ContentHeadingComponent } from './components/content-heading.component';
import { LoadingComponent } from './components/loading.component';
import { ModalComponent } from './components/modal.component';

import { LabelRequiredDirective } from './directives/label-required.directive';
import { MaskedInputDirective } from './directives/masked-input.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RouterModule } from '@angular/router';
import { CloseModalDirective } from './directives/close-modal.directive';
import { AlertComponent } from './components/alert.component';
import { BigLoadingComponent } from './components/big-loading.component';
import { SelectModule } from 'ng2-select';
import { TagInputModule } from 'ngx-chips';
import { DropdownModule, MultiSelectModule, AutoCompleteModule } from 'primeng/primeng';
import { PhonePipe } from './pipes/phone.pipe';
import { ServerImageDirective } from './directives/server-image.directive';
import { MomentModule } from 'angular2-moment';
import { InfosClientComponent } from './components/infosClient.component';
import { PaginationComponent } from './components/pagination.component';
import { ServerLinkDirective } from './directives/server-link.directive';
import { IboxToolsComponent } from './components/ibox-tools.component';
import { ChartModule } from 'angular-highcharts';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatDatepickerModule, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { InfosTrackingComponent } from './components/infosTracking.component';
import { ValuesPipe } from './pipes/values.pipe';
import { MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { AgmCoreModule } from '@agm/core';
import { ModalHistoriqueStatutsComponent } from './components/modal-historique-statuts.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule.forRoot(),
        TypeaheadModule.forRoot(),
        RouterModule,
        FileUploadModule,
        TagInputModule,
        ZXingScannerModule,
        MomentModule,
        ChartModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        Nl2BrPipeModule,
        AgmCoreModule
    ],
    declarations: [
        ContentHeadingComponent,
        LoadingComponent,
        BigLoadingComponent,
        IboxToolsComponent,
        ModalComponent,
        // PaginatorComponent,
        PaginationComponent,
        AlertComponent,
        InfosClientComponent,
        InfosTrackingComponent,
        StatutComponent,
        CloseModalDirective,
        LabelRequiredDirective,
        MaskedInputDirective,
        ServerImageDirective,
        ServerLinkDirective,
        PhonePipe,
        ValuesPipe,
        ModalHistoriqueStatutsComponent,
        // PdfViewerComponent
    ],
    entryComponents: [
        ModalHistoriqueStatutsComponent
    ],
    exports: [
        ContentHeadingComponent,
        LoadingComponent,
        BigLoadingComponent,
        IboxToolsComponent,
        ModalComponent,
        // PaginatorComponent,
        PaginationComponent,
        AlertComponent,
        InfosClientComponent,
        InfosTrackingComponent,
        StatutComponent,
        ModalHistoriqueStatutsComponent,
        CloseModalDirective,
        LabelRequiredDirective,
        ServerImageDirective,
        ServerLinkDirective,
        MaskedInputDirective,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AlertModule,
        TypeaheadModule,
        RouterModule,
        FileUploadModule,
        RoundProgressModule,
        SelectModule,
        TagInputModule,
        ZXingScannerModule,
        MultiSelectModule,
        DropdownModule,
        AutoCompleteModule,
        PhonePipe,
        ValuesPipe,
        MomentModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        Nl2BrPipeModule,
        // PdfViewerComponent,
        AgmCoreModule
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: {
            parse: {
                dateInput: 'DD/MM/YYYY'
            },
            display: {
                dateInput: 'dddd DD MMMM YYYY',
                monthYearLabel: 'MMMM YYYY',
            }
        } },
    ]
})
export class SharedModule { }
