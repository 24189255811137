import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-repeat-tab',
    template: `
        <div class="tabs-container">
            <ul class="nav nav-tabs" role="tablist">
                <li *ngFor="let field of field.fieldGroup; let i = index;">
                    <a class="nav-link" data-toggle="tab" 
                    [ngClass]="{'active' : selectedFormIndex == i }" (click)="selectedFormIndex = i">
                        Produit #{{ i }} <i class="fa fa-times" (click)="remove(i)"></i>
                    </a>
                </li>
                <li>
                    <a class="nav-link" data-toggle="add" (click)="add()">
                        <i class="fa fa-plus"></i> {{ to.btnText }}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div role="tabpanel" *ngFor="let field of field.fieldGroup; let i = index;" [ngClass]="{'active' : selectedFormIndex == i }" class="tab-pane">
                    <div class="row">
                        <div class="bg-white col-12 pb-30">
                            <div class="row bg-orange mb-20">
                                <h2 class="pull-left al-center">
                                    Produit #{{ i }}
                                </h2>
                            </div>
                            <div class="col-12">
                                <formly-group [model]="model[i]" [field]="field" [options]="options" [form]="formControl">
                                </formly-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class FormlyFieldRepeatTab extends FieldArrayType implements OnInit {
    selectedFormIndex: number = 0;

    constructor(builder: FormlyFormBuilder) {
        super(builder);
    }

    ngOnInit() {
        this.to.btnText = this.to.btnText || 'Ajouter';
    }
}
