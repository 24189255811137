import { Commentaire } from "./models/commentaire.model";
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../core/config';

import 'rxjs/add/operator/toPromise';
import { Dossier } from './models/dossier.model';
import { Statut } from '../shared/models/statut.model';
import { StatutNumbers } from '../shared/models/statutNumbers.model';
import { plainToClass } from "class-transformer";
import { Campagne } from "../shared/models/campagne.model";

@Injectable()
export class DemandesInternetService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/demande-internet';
    }

    numbers(): Promise<StatutNumbers[]> {
        return this.http.get(this.url + '/numbers/')
            .toPromise()
            .then(response => response.json().map(r => new StatutNumbers().deserialize(r)));
        ;
    }

    duplicates(dossier: Dossier): Promise<Dossier[]> {
        return this.http.get(this.url + '/duplicates/' + dossier._id)
            .toPromise()
            .then(response => response.json().map(r => new Dossier().deserialize(r)));
        ;
    }

    liste(page:number, statut: string): Promise<{ dossiers : Dossier[], maxPage: number }> {
        return this.http.get(this.url + '/list/' + page + (statut ? ('/' + statut) : ''))
            .toPromise()
            .then(response => {
                var res = response.json();
                var data = res.dossiers as Dossier[];

                var ret = data.map(i => new Dossier().deserialize(i));

                return { dossiers: ret, maxPage : res.maxPage };
            })
            ;
    }

    search(page: number, search): Promise<{ dossiers: Dossier[], maxPage: number }> {
        return this.http.post(this.url + '/search/' + page, search)
            .toPromise()
            .then(response => {
                var res = response.json();
                var data = res.dossiers as Dossier[];

                var ret = data.map(i => new Dossier().deserialize(i));

                return { dossiers: ret, maxPage: res.maxPage };
            })
            ;
    }

    info(entity: Dossier): Promise<Dossier> {
        return this.http.get(this.url + '/info/' + entity._id)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    campagnes(): Promise<Campagne[]> {
        return this.http.get(`${this.url}/campagnes`)
            .toPromise()
            .then(response => response.json().map(item => plainToClass(Campagne, item)))
        ;
    }

    statuts(): Promise<Statut[]> {
        return this.http.get(this.url + '/statuts')
            .toPromise()
            .then(response => response.json().map(s => new Statut().deserialize(s)))
            ;
    }

    ajouter(entity: Dossier, produitIds): Promise<Dossier> {
        entity = Object.assign(entity, produitIds);

        return this.http.post(this.url + '/add', entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    modifier(entity: Dossier, produitIds): Promise<Dossier> {
        entity = Object.assign(entity, produitIds);


        return this.http.put(this.url + '/edit/' + entity._id, entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    changerStatut(entity: Dossier, identifiant: string, commentaire: string): Promise<Dossier> {

        return this.http.put(this.url + '/changer-statut/' + entity._id, { identifiant: identifiant, commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.demandeInternet.deserializeStatuts(response.json());

                entity.demandeInternet.statut = newStat.statut;
                entity.demandeInternet.statuts.push(newStat);

                return entity;
            })
            ;
    }

    priseEnCharge(entity: Dossier, produitIds): Promise<Dossier> {
        entity = Object.assign(entity, produitIds);

        let route = '';
        if (entity._id)
            route = '/' + entity._id;

        return this.http.put(this.url + '/prise-en-charge' + route, entity)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
            ;
    }

    retirerStatut(entity: Dossier, commentaire: string): Promise<Dossier> {
        return this.http.put(this.url + '/retirer-statut/' + entity._id, { commentaire: commentaire })
            .toPromise()
            .then(response => {
                var newStat = entity.demandeInternet.deserializeStatuts(response.json());

                entity.demandeInternet.statut = newStat.statut;
                entity.demandeInternet.statuts.push(newStat);


                return entity;
            })
            ;
    }

    getCommentaires(dossierId: string): Promise<Commentaire[]> {
        return this.http.get(this.url + '/commentaires/' + dossierId)
            .toPromise()
            .then(response => response.json().map(item => plainToClass(Commentaire, item)))
        ;
    }

    ajouterCommentaire(dossierId: string, texte: string) {
        return this.http.post(this.url + '/commentaire/' + dossierId, { texte }).toPromise();
    }

    colisEnvoyeDirectementParClient(dossierId: string, texte: string) {
        return this.http.put(this.url + '/colis-envoye-directement-par-client/' + dossierId, { texte }).toPromise();
    }

    activerCodePromoPhysique(entity: Dossier, code: string): Promise<Dossier> {
        return this.http.put(this.url + '/activer-code-promo-physique/' + entity._id + '/' + code, null)
            .toPromise()
            .then(response => entity.deserialize(response.json()))
        ;
    }

}
