import { ParametresService } from './services/parametres.service';
import { PrinterService } from './services/printer.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpModule, Http, XHRBackend, RequestOptions } from '@angular/http';
import { InterceptorService } from 'ng2-interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';

import { ModalPlaceholderDirective } from './directives/modal-placeholder.directive';

import { ConfigService } from './config';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ModalService } from './services/modal.service';
import { Broadcaster } from './services/broadcaster.service';
import { MiscService } from './services/misc.service';
import { SettingsService } from './services/settings.service';

import { BasicGuard, AdminGuard } from './auth.guard';

// import { FileService } from './services/file.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { UtilisateursService } from '../utilisateurs/utilisateurs.service';
import { AlertService } from './services/alert.service';
import { ReferencesService } from '../references/references.service';
import { FileService } from './services/file.service';
import { PartenairesService } from '../partenaires/partenaires.service';
import { DemandesInternetService } from '../demandesInternet/demandesInternet.service';
import { ReparationService } from '../reparations/reparations.service';
import { DevisService } from '../reparations/devis.service';
import { PanneService } from '../pannes/pannes.service';
import { StatsService } from '../stats/stats.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AgmCoreModule } from '@agm/core';
// import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

export function interceptorFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions, httpInterceptor: HttpInterceptorService) {
    const service = new InterceptorService(xhrBackend, requestOptions);
    service.addInterceptor(httpInterceptor);
    return service;
}

// export function createTranslateLoader(http: HttpClient) {
//     return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
    imports: [
        HttpModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: (createTranslateLoader),
        //         deps: [Http]
        //     }
        // }),
        AgmCoreModule.forRoot({
            apiKey: ConfigService.map.googleMapsApiKey,
            libraries: ['places', 'geocoder', 'visualization', 'geometry']
        }),
    ],
    providers: [
        StorageService,
        AuthService,
        HttpInterceptorService,
        UtilisateursService,
        ReferencesService,
        DevisService,
        PartenairesService,
        ConfigService,
        AlertService,
        ModalService,
        Broadcaster,
        MiscService,
        NotificationService,
        AuthService,
        FileService,
        StatsService,
        SettingsService,
        DemandesInternetService,
        PanneService,
        ReparationService,
        PrinterService,
        ParametresService,
        // GoogleMapsAPIWrapper,
        HttpInterceptorService,
        {
            provide: Http,
            useFactory: interceptorFactory,
            deps: [ XHRBackend, RequestOptions, HttpInterceptorService ]
        },
        {
            provide: HttpClient,
            useFactory: interceptorFactory,
            deps: [ XHRBackend, RequestOptions, HttpInterceptorService ]
        },
        BasicGuard,
        AdminGuard
    ],
    declarations: [
        ModalPlaceholderDirective,
    ],
    exports: [
        ModalPlaceholderDirective,
        SharedModule,
        AgmCoreModule
    ]
})
export class CoreModule {

    constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

}
