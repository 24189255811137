import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { ConfigService } from '../config';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class MiscService {

    url: string;

    constructor(
        private http: Http,
        private config: ConfigService
    ) {
        this.url = this.config.api.url + '/misc';
    }

    types(): Promise<any> {
        return this.http.get(this.url + '/types')
            .toPromise()
            .then(response => response.json())
        ;
    }

    getType(identifier: string): Promise<any> {
        return this.http.get(this.url + '/types/' + identifier)
            .toPromise()
            .then(response => response.json());
    }

}

