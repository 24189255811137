import { Broadcaster } from './broadcaster.service';
import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { ConfigService } from '../config';
import { Http } from '@angular/http';

export enum ParamKey {
    DelaisReparation = 'delais_reparation',
}

interface ParametreResponseDto {
    key: ParamKey;
    value: string | number;
}

interface EditParametreDto {
    key: ParamKey;
    value: string | number;
}

@Injectable()
export class ParametresService {

    url: string;
    parametres: ParametreResponseDto[];

    constructor(
        private alertService: AlertService,
        private http: Http,
        private config: ConfigService,
        private broadcaster: Broadcaster
    ) {
        this.url = this.config.api.url + '/parametres';
    }

    async getParametre(key: ParamKey): Promise<string | number> {
        if (!this.parametres) {
            this.parametres = await this.http.get(`${this.url}/liste`).toPromise().then(response => response.json() as ParametreResponseDto[]);
        }

        const parametre = this.parametres.find(item => item.key === key);

        return parametre ? parametre.value : null;
    }

    async setParametre(key: ParamKey)  {
        const value = await this.getParametre(key);
        const message = this.getPromptMessage(key);
        const newValue = await this.alertService.prompt(message, value as string);

        if (newValue) {
            const dto: EditParametreDto = { key, value: newValue };
            await this.http.put(`${this.url}/edit`, dto).toPromise();
            this.parametres = this.parametres.map(item =>  item.key === key ? dto : item);
            await this.broadcaster.broadcast(`parametres:updated:${key}`, newValue);
        }
    }

    private getPromptMessage(key: ParamKey): string {
        const messages = [
            { key: ParamKey.DelaisReparation, message: `Délais réparation (ex : 1 semaine)` },
        ];

        return messages.find(item => item.key === key).message;
    }

}
