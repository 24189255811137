import { Component, Input } from '@angular/core';

@Component({
    selector: 'content-heading',
    template: `
        <div class="content-heading">
            {{ titre }}
            <div class="pull-right">
                <ng-content></ng-content>
            </div>
            <small *ngIf="sousTitre">{{ sousTitre }}</small>
        </div>
    `
})
export class ContentHeadingComponent {
    @Input() titre;
    @Input() sousTitre;
}