import { Contact } from "../../shared/models/contact.model";
import { Adresse } from "../../shared/models/adresse.model";
import { Deserializable } from "../../shared/models/deserializable.model";

export class Societe implements Deserializable
 {

    constructor(
        public _id?: string,
        public nom?: string,
        public raisonSociale?: string,
        public enseigne?: string,
        public siret?: string,
        public kbis?: string,
        public pieceIdentite?: string,

        public contact?: Contact,
        public representantLegal?: Contact,

        public adresseSiege?: Adresse,
        public adresseEnelevement?: Adresse,
        public adresseLivraison?: Adresse
    ) {
        this.adresseSiege = new Adresse();
        this.adresseEnelevement = new Adresse();
        this.adresseLivraison = new Adresse();

        this.contact = new Contact();
        this.representantLegal = new Contact();
     }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.contact)
            this.contact = new Contact().deserialize(input.contact);

        if (input.representantLegal)
            this.representantLegal = new Contact().deserialize(input.representantLegal);

        if (input.adresseSiege)
            this.adresseSiege = new Adresse().deserialize(input.adresseSiege);

        if (input.adresseEnelevement)
            this.adresseEnelevement = new Adresse().deserialize(input.adresseEnelevement);

        if (input.adresseLivraison)
            this.adresseLivraison = new Adresse().deserialize(input.adresseLivraison);

        return this;
    }

}
