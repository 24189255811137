import { Component, Input, AfterViewInit } from '@angular/core';
import { Params } from '@angular/router';

@Component({
    selector: 'infos-tracking',
    template: `
        <div class="ibox-content">
            <ul class="p-10 al-left">
                <li *ngFor="let item of params|keyvalue">
                    <strong>{{ item.key }} :</strong>
                    {{ item.value }}
                </li>
            </ul>
        </div>
    `
})
export class InfosTrackingComponent implements AfterViewInit {

    @Input() params: {};

    constructor() { }

    ngAfterViewInit() {
        console.log('ara', this.params);
    }
}
