import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'pagination',
    template: `
        <div class="btn-group paginate">
            <button type="button" class="btn btn-white" [ngClass]="{ 'disabled' : page == 1 }" (click)="changePage(11)"><i class="fa fa-step-backward"></i></button>
            <button type="button" class="btn btn-white" [ngClass]="{ 'disabled' : page <= 1 }" (click)="changePage(page - 1)"><i class="fa fa-chevron-left"></i></button>
            <button class="btn btn-white" *ngIf="page > 2" (click)="changePage(page - 2)">{{ page - 2 }}</button>
            <button class="btn btn-white" *ngIf="page > 1" (click)="changePage(page - 1)">{{ page - 1 }}</button>
            <button class="btn btn-white active">{{ page }}</button>
            <button class="btn btn-white" *ngIf="page + 1 <= maxPage" (click)="changePage(page + 1)">{{ page + 1 }}</button>
            <button class="btn btn-white" *ngIf="page + 2 <= maxPage" (click)="changePage(page + 2)">{{ page + 2 }}</button>
            <button type="button" class="btn btn-white" [ngClass]="{ 'disabled' : page >= maxPage }"><i class="fa fa-chevron-right" (click)="changePage(page + 1)"></i> </button>
            <button type="button" class="btn btn-white" [ngClass]="{ 'disabled' : page == maxPage }"><i class="fa fa-step-forward" (click)="changePage(maxPage)"></i> </button>
        </div>
    `
})
export class PaginationComponent {

    @Input() page: number;
    @Input() maxPage: number;
    @Output() change = new EventEmitter<{ page : number }>();

    constructor() { }

    changePage(page) {
        this.change.emit({ page : page });
    }

}
