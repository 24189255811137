import { Deserializable } from "./deserializable.model";
import { ConfigService } from "src/app/core/config";
import { Statut } from "./statut.model";

export class StatutNumbers implements Deserializable {

    constructor(
        public statut ?: Statut,
        public count ?: number
    ) { }

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.statut)
            this.statut = new Statut().deserialize(input.statut);

        return this;
    }

}
