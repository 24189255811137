import { ModalHistoriqueStatutsComponent } from './modal-historique-statuts.component';
import { Component, Input, ComponentFactoryResolver } from '@angular/core';
import { StatutDto, HistoriqueStatutsDto } from '../dtos/statut.dtos';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
    selector: 'app-statut',
    template: `
        <div class="Statut" [ngClass]="{'Statut--withHistorique': !!historique}" (click)="showHistorique()">
            <span class="label label-sm Statut"
                [ngClass]="['label-' + statut.type]">
                {{ statut.nom }}
            </span>
            <ng-template [ngIf]="showLastUpdate">
                <br>
                <small>
                    {{ statut.createdAt | amCalendar: { sameElse: 'DD/MM/YYYY HH:mm' }  }}
                </small>
            </ng-template>
        </div>
    `,
    styles: [`
        .Statut.Statut--withHistorique {
            cursor: pointer;
        }
    `]
})
export class StatutComponent {

    @Input() statut: StatutDto;
    @Input() historique: HistoriqueStatutsDto;
    @Input() showLastUpdate: boolean;

    constructor(
        private readonly modalService: ModalService,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    showHistorique() {
        if (!this.historique) {
            return ;
        }
        this.modalService.open(
            this.componentFactoryResolver,
            ModalHistoriqueStatutsComponent,
            { statuts: this.historique.statuts }
        );
    }

}
